import cx from 'classnames';
import { useId, useState } from 'react';
import { shallow } from 'zustand/shallow';

import { Button, NavigationBar, TextInput } from '@sb/design-system';
import { isDuplicateName } from '@sbrc/utils';

import WidgetView from '../widget-panel/WidgetView';

import { generateNewSpaceItem } from './generateNewSpaceItem';
import { HeaderBackButton } from './HeaderBackButton';
import { useSpaceWidgetStore } from './useSpaceWidgetStore';

export function RenameSpaceItem() {
  const [widgetState, setWidgetState, spaceList = []] = useSpaceWidgetStore(
    (s) => [s.widgetState, s.setWidgetState, s.routine?.space],
    shallow,
  );

  const [name, setName] = useState(widgetState.renameItem?.name ?? '');

  const [description, setDescription] = useState(
    widgetState.renameItem?.description ?? '',
  );

  const handleSubmit: React.FormEventHandler = (event) => {
    event.preventDefault();

    if (widgetState.renameItem) {
      const item = {
        ...widgetState.renameItem,
        name,
        description,
      };

      setWidgetState({
        ...widgetState,
        updateItem: item,
        renameItem: undefined,
      });
    } else if (widgetState.createItemKind) {
      setWidgetState({
        ...widgetState,
        item: generateNewSpaceItem(
          widgetState.createItemKind,
          name,
          description,
        ),
        createItemKind: undefined,
      });
    }
  };

  const actionTitle = widgetState.createItemKind ? 'New' : 'Rename';

  const isDuplicateSpaceName = spaceList.some(
    (spaceItem) =>
      spaceItem !== widgetState.renameItem &&
      isDuplicateName(spaceItem.name, name),
  );

  const isSaveDisabled =
    (name === '' &&
      (widgetState.createItemKind !== undefined ||
        Boolean(widgetState.renameItem))) ||
    isDuplicateSpaceName;

  const inputIDPrefix = useId();

  return (
    <WidgetView>
      <NavigationBar
        className="tw-pl-8 tw-pr-8"
        contentLeft={<HeaderBackButton />}
      >
        {actionTitle} item
      </NavigationBar>

      <form
        onSubmit={handleSubmit}
        className={cx('tw-mx-16', 'tw-mb-16', 'tw-flex', 'tw-flex-col')}
      >
        <label className="tw-heading-40" htmlFor={`${inputIDPrefix}-name`}>
          Name
        </label>

        <TextInput
          id={`${inputIDPrefix}-name`}
          value={name}
          onChange={(value) => {
            setName(value);
          }}
          autoFocus
          className={cx(isDuplicateSpaceName && 'tw-outline-error')}
        />

        {isDuplicateSpaceName && (
          <p className={cx('tw-text-13', 'tw-text-red', 'tw-mt-8')}>
            Name already exists.
          </p>
        )}

        <label
          className="tw-heading-40"
          htmlFor={`${inputIDPrefix}-description`}
        >
          Description
        </label>

        <TextInput
          id={`${inputIDPrefix}-description`}
          value={description}
          onChange={(value) => setDescription(value)}
        />

        <Button
          type="submit"
          variant="Filled"
          disabled={isSaveDisabled}
          className={cx('tw-rounded-6', 'tw-mt-16')}
        >
          {widgetState.renameItem ? 'Save' : 'Create'}
        </Button>
      </form>
    </WidgetView>
  );
}
