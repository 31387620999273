import cx from 'classnames';
import { useEffect } from 'react';
import { shallow } from 'zustand/shallow';

import { Icon, NavigationBar } from '@sb/design-system';
import type { ArmJointPositions } from '@sb/motion-planning';
import type { Routine } from '@sb/remote-control/types';
import type { Space } from '@sb/routine-runner';
import { useToast } from '@sbrc/hooks';
import { updateRoutine } from '@sbrc/services';

import WidgetView from '../widget-panel/WidgetView';

import { EditSpaceItem } from './edit-space-item/EditSpaceItem';
import { RenameSpaceItem } from './RenameSpaceItem';
import { SpaceList } from './SpaceList';
import { useSpaceWidgetStore } from './useSpaceWidgetStore';
import { useVisualizeSpace } from './visualize-space/useVisualizeSpace';

interface SpaceWidgetProps {
  routine: Routine.ConvertedResponse | null;
  isActive: boolean;
  robotID: string;
  isVizbot: boolean;
  targetJointAngles: ArmJointPositions | null;
  setTargetJointAngles: (jointAngles: ArmJointPositions | null) => void;
}

export function SpaceWidget({
  routine,
  isActive,
  robotID,
  isVizbot,
  targetJointAngles,
  setTargetJointAngles,
}: SpaceWidgetProps) {
  const [sync, widgetState, setWidgetState] = useSpaceWidgetStore(
    (s) => [s.sync, s.widgetState, s.setWidgetState] as const,
    shallow,
  );

  useEffect(
    () =>
      sync({
        robotID,
        isVizbot,
        routine,
        targetJointAngles,
        setTargetJointAngles,
      }),
    [sync, robotID, isVizbot, routine, targetJointAngles, setTargetJointAngles],
  );

  const { setToast } = useToast();

  useEffect(() => {
    if (!routine) {
      return;
    }

    const { updateItem, removeItemID } = widgetState;

    if (widgetState.updateItem || widgetState.removeItemID) {
      // only run this effect once when the item requires update/remove
      setWidgetState({
        ...widgetState,
        updateItem: undefined,
        removeItemID: undefined,
      });
    }

    const updateSpace = async (space: Space.Item[]) => {
      try {
        await updateRoutine({ space }, routine.id);
      } catch (error) {
        setToast({ kind: 'error', message: error.message });
      }
    };

    if (updateItem) {
      const space = [...routine.space];

      const currentIndex = space.findIndex((item) => item.id === updateItem.id);

      if (currentIndex > -1) {
        space[currentIndex] = updateItem;
      } else {
        space.push(updateItem);
      }

      updateSpace(space);
    }

    if (removeItemID) {
      const space = routine.space.filter((item) => item.id !== removeItemID);

      if (space.length < routine.space.length) {
        updateSpace(space);
      }
    }
  }, [widgetState, setWidgetState, routine, setToast]);

  useVisualizeSpace();

  if (!isActive) {
    return null;
  }

  if (!routine) {
    return (
      <WidgetView>
        <NavigationBar>Space</NavigationBar>
        <>
          <Icon
            kind="infoCircle"
            className={cx('tw-text-blue', 'tw-mx-auto')}
          />
          <p
            className={cx(
              'tw-flex-none',
              'tw-text-15',
              'tw-text-label-tertiary',
              'tw-mt-12',
              'tw-m-32',
              'tw-text-center',
            )}
          >
            Load a routine onto the robot to configure space.
          </p>
        </>
      </WidgetView>
    );
  }

  if (widgetState.createItemKind || widgetState.renameItem) {
    return <RenameSpaceItem />;
  }

  if (widgetState.item) {
    return <EditSpaceItem />;
  }

  return <SpaceList />;
}
