import { useLoader } from '@react-three/fiber';
import { OBJLoader } from 'three-stdlib';

import type { InnerObjectProps } from './useInnerObject';
import { useInnerObject } from './useInnerObject';

export function InnerOBJObject({ environmentObject }: InnerObjectProps) {
  const object = useLoader(OBJLoader, environmentObject.fileURL!);

  return useInnerObject(object, environmentObject);
}
