import { useGLTF } from '@react-three/drei';

import type { InnerObjectProps } from './useInnerObject';
import { useInnerObject } from './useInnerObject';

export function InnerGLTFObject({ environmentObject }: InnerObjectProps) {
  const gltf = useGLTF(environmentObject.fileURL!);

  return useInnerObject(gltf.scene, environmentObject);
}
