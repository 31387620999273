import { useCallback } from 'react';
import { shallow } from 'zustand/shallow';

import type { RoutineRunnerState, Space } from '@sb/routine-runner';
import { useRobotState } from '@sbrc/hooks';

import { useSpaceWidgetStore } from '../useSpaceWidgetStore';

import { VisualizePlaneSurface } from './plane/VisualizePlaneSurface';
import { VisualizePositions } from './position-list/VisualizePositions';

interface VisualizeVisionPositionListProps {
  spaceItem: Space.VisionPositionList;
}

export function VisualizeVisionPositionList({
  spaceItem,
}: VisualizeVisionPositionListProps) {
  const [planeItem, isVizbot, isEditing, currentPositionIndex] =
    useSpaceWidgetStore(
      (s) =>
        [
          s.routine?.space.find(
            (item): item is Space.Plane =>
              item.id === spaceItem.planeID && item.kind === 'plane',
          ),
          s.isVizbot,
          s.widgetState.item?.id === spaceItem.id,
          s.widgetState.currentPositionIndex ?? 0,
        ] as const,
      shallow,
    );

  const resultSelector = useCallback(
    (state: RoutineRunnerState | null) => {
      if (state?.kind === 'RoutineRunning') {
        const stepVariables = state.variables[spaceItem.id];

        if (stepVariables?.stepKind === 'Locate') {
          return stepVariables.latestResult ?? [];
        }
      }

      return null;
    },
    [spaceItem.id],
  );

  const latestResult = useRobotState({ isVizbot }, resultSelector);

  if (isEditing || latestResult) {
    // show the result from the step if a routine is running, otherwise
    // show the result set by the `useVisionPositionList` hook
    const positions = latestResult ?? spaceItem.positions;

    return (
      <>
        {planeItem && <VisualizePlaneSurface spaceItem={planeItem} />}
        <VisualizePositions
          positions={positions}
          currentPositionIndex={latestResult ? undefined : currentPositionIndex}
        />
      </>
    );
  }

  return null;
}
