import { useLoader } from '@react-three/fiber';
import { useMemo } from 'react';
import { Color, Mesh, MeshStandardMaterial } from 'three';
import { STLLoader } from 'three-stdlib';

import type { InnerObjectProps } from './useInnerObject';
import { useInnerObject } from './useInnerObject';

export function InnerSTLObject({ environmentObject }: InnerObjectProps) {
  const geometry = useLoader(STLLoader, environmentObject.fileURL!);

  const object = useMemo(() => {
    return new Mesh(
      geometry,
      new MeshStandardMaterial({ color: new Color('#888888') }),
    );
  }, [geometry]);

  return useInnerObject(object, environmentObject);
}
