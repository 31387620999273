import { useSpaceWidgetStore } from '../useSpaceWidgetStore';

import { EditEnvironmentObject } from './EditEnvironmentObject';
import { EditPositionList } from './EditPositionList';
import { EditSinglePosition } from './EditSinglePosition';
import { EditGridPositionList } from './grid-position-list/EditGridPositionList';
import { EditPlane } from './plane/EditPlane';
import { EditVisionPositionList } from './vision-position-list/EditVisionPositionList';

export function EditSpaceItem() {
  const spaceItem = useSpaceWidgetStore((s) => s.widgetState.item);

  if (!spaceItem) {
    return null;
  }

  const { kind } = spaceItem;

  switch (kind) {
    case 'freeformPositionList':
      return <EditPositionList spaceItem={spaceItem} />;
    case 'gridPositionList':
      return <EditGridPositionList spaceItem={spaceItem} />;
    case 'singlePosition':
      return <EditSinglePosition spaceItem={spaceItem} />;
    case 'visionPositionList':
      return <EditVisionPositionList spaceItem={spaceItem} />;
    case 'plane':
      return <EditPlane spaceItem={spaceItem} />;
    case 'environmentObject':
      return <EditEnvironmentObject spaceItem={spaceItem} />;
    default: {
      const exhaustive: never = kind; // ensure type exhaustive
      throw new Error(`Unexpected kind for equipment ${exhaustive}`);
    }
  }
}
