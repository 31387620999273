import cx from 'classnames';
import { shallow } from 'zustand/shallow';

import { NavigationBar, SettingsGroup } from '@sb/design-system';
import type { Space } from '@sb/routine-runner';
import { useRoutineRunnerHandle } from '@sbrc/hooks';
import { getRobotPosition } from '@sbrc/utils';

import WidgetView from '../../widget-panel/WidgetView';
import { HeaderBackButton } from '../HeaderBackButton';
import { UNSAVED_POSITION } from '../types';
import { useSpaceWidgetStore } from '../useSpaceWidgetStore';

import { LabeledPositionItem } from './position-item/LabeledPositionItem';

interface EditSinglePositionProps {
  spaceItem: Space.SinglePosition;
}

export function EditSinglePosition({ spaceItem }: EditSinglePositionProps) {
  const [isVizbot, widgetState, setWidgetState] = useSpaceWidgetStore(
    (s) => [s.isVizbot, s.widgetState, s.setWidgetState] as const,
    shallow,
  );

  const routineRunnerHandle = useRoutineRunnerHandle({ isVizbot });

  const position = spaceItem.positions[0] ?? UNSAVED_POSITION;

  const handleSavePosition = () => {
    const robotPosition = getRobotPosition(routineRunnerHandle);

    if (robotPosition) {
      const item = {
        ...spaceItem,
        positions: [robotPosition],
      };

      setWidgetState({
        ...widgetState,
        item,
        updateItem: item,
      });
    }
  };

  return (
    <WidgetView>
      <NavigationBar
        className="tw-pl-8 tw-pr-8"
        contentLeft={<HeaderBackButton />}
      >
        {spaceItem.name}
      </NavigationBar>

      <p className={cx('tw-mx-16', 'tw-text-15')}>
        {position === UNSAVED_POSITION ? (
          <>
            Move the arm to the required position then return to this panel and
            tap the “Set” button.
          </>
        ) : (
          <>
            <strong>Position saved.</strong> To change, move the arm to the
            required position then return to this panel and select the “Replace
            position” menu option.
          </>
        )}
      </p>

      <SettingsGroup className="tw-m-16">
        <LabeledPositionItem position={position} onSave={handleSavePosition} />
      </SettingsGroup>
    </WidgetView>
  );
}
