import { shallow } from 'zustand/shallow';

import { ZERO_POSE } from '@sb/geometry';
import type { Space } from '@sb/routine-runner';

import { useSpaceWidgetStore } from '../../useSpaceWidgetStore';

import { EnvironmentObject } from './EnvironmentObject';
import { EnvironmentObjectPivotControls } from './EnvironmentObjectPivotControls';

interface VisualizeEnvironmentObjectProps {
  spaceItem: Space.EnvironmentObject;
}

export function VisualizeEnvironmentObject({
  spaceItem,
}: VisualizeEnvironmentObjectProps) {
  const [widgetState, setWidgetState, isEditing] = useSpaceWidgetStore(
    (s) => [
      s.widgetState,
      s.setWidgetState,
      s.widgetState.item?.id === spaceItem.id,
    ],
    shallow,
  );

  if (!spaceItem.fileURL) {
    return null;
  }

  if (isEditing) {
    return (
      <EnvironmentObjectPivotControls
        key={spaceItem.id}
        onDragEnd={(pose) => {
          const item = { ...spaceItem, pose };
          setWidgetState({ ...widgetState, item, updateItem: item });
        }}
        initialPose={spaceItem.pose}
      >
        <EnvironmentObject
          environmentObject={{ ...spaceItem, pose: ZERO_POSE }}
        />
      </EnvironmentObjectPivotControls>
    );
  }

  return <EnvironmentObject key={spaceItem.id} environmentObject={spaceItem} />;
}
