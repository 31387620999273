import { Modal, NavigationBar, NavigationBarButton } from '@sb/design-system';
import type { CartesianPose } from '@sb/geometry';
import {
  CameraStream,
  useVisionChessboardCorners,
  ChessboardOverlay,
} from '@sbrc/components/camera';

import { CreateWithCameraMessageOverlay } from './CreateWithCameraMessageOverlay';

interface CreateWithCameraModalProps {
  isOpen: boolean;
  onClose: () => void;
  onCapture: (transform: CartesianPose) => void;
}

export function CreateWithCameraModal({
  isOpen,
  onClose,
  onCapture,
}: CreateWithCameraModalProps) {
  const { chessboardCorners, handleCapture } = useVisionChessboardCorners({
    isOpen,
    onCapture: (transform) => {
      onCapture(transform);
      onClose();
    },
  });

  return (
    <Modal
      isOpen={isOpen}
      /* don't close on click outside */
      onClose={() => {}}
      className="tw-w-[90vw] tw-h-[80vh] tw-dark"
    >
      <NavigationBar
        className="tw-flex-none"
        contentLeft={
          <NavigationBarButton onClick={onClose}>Cancel</NavigationBarButton>
        }
        contentRight={
          <NavigationBarButton
            onClick={handleCapture}
            disabled={!chessboardCorners || chessboardCorners.length === 0}
          >
            Capture
          </NavigationBarButton>
        }
      >
        Create plane
      </NavigationBar>
      <div className="tw-flex-1 tw-h-0 tw-m-24 tw-mt-8 tw-flex tw-items-center tw-justify-center">
        <CameraStream>
          {chessboardCorners && (
            <>
              <ChessboardOverlay points={chessboardCorners} />
              <CreateWithCameraMessageOverlay
                isFound={chessboardCorners.length > 0}
              />
            </>
          )}
        </CameraStream>
      </div>
    </Modal>
  );
}
