import { Circle, GradientTexture, GradientType } from '@react-three/drei';
import { useEffect, useMemo, useRef, useState } from 'react';
import { DoubleSide, Vector3 } from 'three';

import { getPlaneNormal, type Plane } from '@sb/geometry';
import type { Space } from '@sb/routine-runner';

import { BoundaryLine } from '../BoundaryLine';
import { Puck } from '../Puck';

import { VisualizeCameraFOV } from './VisualizeCameraFOV';

interface VisualizePlaneSurfaceProps {
  spaceItem: Space.Plane;
  showAxes?: boolean;
}

export function VisualizePlaneSurface({
  spaceItem,
  showAxes,
}: VisualizePlaneSurfaceProps) {
  const origin = spaceItem.positions[0]?.pose;
  const plusX = spaceItem.positions[1]?.pose;
  const plusY = spaceItem.positions[2]?.pose;
  const lineColor = '#8E8C8F'; // metal.70
  const circleRef = useRef<React.ComponentRef<typeof Circle>>(null);

  const [plusZ, setPlusZ] = useState<Vector3 | undefined>();

  const plane = useMemo<Plane | undefined>(() => {
    if (origin && plusX && plusY) {
      return {
        origin: new Vector3(origin.x, origin.y, origin.z),
        plusX: new Vector3(plusX.x, plusX.y, plusX.z),
        plusY: new Vector3(plusY.x, plusY.y, plusY.z),
      };
    }

    return undefined;
  }, [origin, plusX, plusY]);

  useEffect(() => {
    if (!circleRef.current) {
      return;
    }

    if (plane) {
      const normal = getPlaneNormal(plane);

      circleRef.current.visible = true;

      circleRef.current.position.set(normal.x, normal.y, normal.z);

      circleRef.current.rotation.set(0, 0, 0);
      circleRef.current.lookAt(new Vector3(0, 0, 0));

      circleRef.current.position.set(origin.x, origin.y, origin.z);

      setPlusZ(
        new Vector3(origin.x, origin.y, origin.z).add(
          normal.multiplyScalar(0.3),
        ),
      );
    } else {
      circleRef.current.visible = false;
      setPlusZ(undefined);
    }
  }, [origin, plane]);

  return (
    <>
      {showAxes && (
        <>
          <Puck position={origin} label="O" />
          <Puck position={plusX} label="+X" />
          <Puck position={plusY} label="+Y" />
          <Puck position={plusZ} label="+Z" />
          <BoundaryLine start={origin} end={plusX} color={lineColor} />
          <BoundaryLine start={origin} end={plusY} color={lineColor} />
          <BoundaryLine start={origin} end={plusZ} color={lineColor} />
        </>
      )}

      <Circle ref={circleRef} args={[1, 32]} visible={false}>
        <meshBasicMaterial transparent side={DoubleSide} depthWrite={false}>
          <GradientTexture
            stops={[0, 0.6, 0.7]}
            colors={['#22222288', '#22222244', '#22222200']}
            size={100}
            width={100}
            type={GradientType.Radial as any}
          />
        </meshBasicMaterial>
      </Circle>

      {plane && <VisualizeCameraFOV plane={plane} />}
    </>
  );
}
