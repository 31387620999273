import type { Space } from '@sb/routine-runner';

import { UNSAVED_POSITION } from '../../types';

import { isCalibrationEntryLocateResult } from './isCalibrationEntryLocateResult';

export function getUpdateItem(spaceItem: Space.Plane): Space.Plane | undefined {
  // don't save partially defined plane
  if (spaceItem.positions.some((p) => p === UNSAVED_POSITION)) {
    return undefined;
  }

  const updateItem = { ...spaceItem };

  // remove locate results which have not been added as calibration entries
  updateItem.calibrationEntries = updateItem.calibrationEntries.filter(
    (entry) => !isCalibrationEntryLocateResult(entry),
  );

  return updateItem;
}
