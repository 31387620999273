import { useState } from 'react';

import { Icon, Popper } from '@sb/design-system';

export interface PositionItemMoreMenuProps {
  content: React.ReactNode;
}

export function PositionItemMoreMenu({ content }: PositionItemMoreMenuProps) {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  return (
    <Popper
      isOpen={isMenuOpen}
      onClose={() => setIsMenuOpen(false)}
      content={content}
      onClick={() => setIsMenuOpen(!isMenuOpen)}
      placement="bottom-end"
      skidding={13}
      className="tw-flex-none"
    >
      <Icon
        kind="ellipsisCircle"
        className="tw-text-label-secondary tw-mr-[--tw-icon-terminal-margin]"
      />
    </Popper>
  );
}
