import { useEffect } from 'react';

import { useVisualizerChildren } from '@sbrc/hooks';

import { VisualizeSpaceItems } from './VisualizeSpaceItems';

const CHILD_KEY = 'spaceItems';

export function useVisualizeSpace() {
  const { setVisualizerChild } = useVisualizerChildren();

  useEffect(() => {
    setVisualizerChild(CHILD_KEY, <VisualizeSpaceItems />);

    // remove visualization on unmount
    return () => {
      setVisualizerChild(CHILD_KEY, null);
    };
  }, [setVisualizerChild]);
}
