import type { Space } from '@sb/routine-runner';

export function getSpaceItemKindName(itemKind: Space.ItemKind) {
  switch (itemKind) {
    case 'singlePosition':
      return 'Single position';
    case 'gridPositionList':
      return 'Grid position list';
    case 'freeformPositionList':
      return 'Freeform position list';
    case 'visionPositionList':
      return 'Vision position list';
    case 'plane':
      return 'Plane';
    case 'environmentObject':
      return 'Object';
    default: {
      const exhaustive: never = itemKind; // ensure type exhaustive

      return `${exhaustive} Item`;
    }
  }
}
