import { shallow } from 'zustand/shallow';

import {
  NavigationBar,
  NavigationList,
  NavigationListIcon,
  NavigationListItem,
  NavigationListLabel,
} from '@sb/design-system';
import { useFeatureFlag } from '@sbrc/hooks';

import WidgetView from '../../../widget-panel/WidgetView';
import { HeaderBackButton } from '../../HeaderBackButton';
import { useSpaceWidgetStore } from '../../useSpaceWidgetStore';

export function PickCreationMethod() {
  const [setWidgetCurrentView, itemName = ''] = useSpaceWidgetStore(
    (s) => [s.setWidgetCurrentView, s.widgetState.item?.name] as const,
    shallow,
  );

  const isCameraEnabled = useFeatureFlag('camera');

  return (
    <WidgetView>
      <NavigationBar
        className="tw-pl-8 tw-pr-8"
        contentLeft={<HeaderBackButton />}
      >
        {itemName}
      </NavigationBar>

      <div className="tw-mx-16 tw-text-15 tw-text-label-secondary tw-flex tw-flex-col tw-gap-16">
        <p>
          Planes help orient the robot’s movements around a surface in its
          space. This is useful for working with angled surfaces and fixtures.
        </p>
        {isCameraEnabled && (
          <p>
            Planes are also used in vision steps. For best accuracy, the plane
            should be created with the camera and a calibration board.
          </p>
        )}
      </div>

      <NavigationList className="tw-mt-8 tw-mb-16">
        <NavigationListItem
          onClick={() => setWidgetCurrentView('createWithArm')}
        >
          <NavigationListIcon kind="tooltip" className="tw-bg-blue" />
          <NavigationListLabel subtitleBelow="For general use">
            Create with arm
          </NavigationListLabel>
        </NavigationListItem>

        {isCameraEnabled && (
          <NavigationListItem
            onClick={() => setWidgetCurrentView('createWithCamera')}
          >
            <NavigationListIcon
              kind="equipmentCamera"
              className="tw-bg-mango"
            />
            <NavigationListLabel>Create with camera</NavigationListLabel>
          </NavigationListItem>
        )}
      </NavigationList>
    </WidgetView>
  );
}
