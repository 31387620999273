import { useEffect, useState } from 'react';
import { shallow } from 'zustand/shallow';

import { cartesianPositionFromPose } from '@sb/geometry';
import type { Space } from '@sb/routine-runner';
import { wait } from '@sb/utilities';
import { useRoutineRunnerHandle } from '@sbrc/hooks';

import { NULL_JOINT_ANGLES } from '../../types';
import { useSpaceWidgetStore } from '../../useSpaceWidgetStore';

type ResultStatus = 'init' | 'loading' | 'loaded' | 'error';

interface UseVisionPositionListArgs {
  spaceItem: Space.VisionPositionList;
  applyTransform: boolean;
}

export function useVisionPositionList({
  spaceItem,
  applyTransform,
}: UseVisionPositionListArgs) {
  const [isVizbot, stepConfiguration, setWidgetState, space] =
    useSpaceWidgetStore(
      (s) =>
        [
          s.isVizbot,
          s.routine?.stepConfigurations[spaceItem.id],
          s.setWidgetState,
          s.routine?.space,
        ] as const,
      shallow,
    );

  const routineRunnerHandle = useRoutineRunnerHandle({ isVizbot });

  const [status, setStatus] = useState<ResultStatus>('init');

  const getResults = async () => {
    if (
      stepConfiguration?.stepKind === 'Locate' &&
      stepConfiguration.args &&
      stepConfiguration.args.planeID
    ) {
      const { planeID } = stepConfiguration.args;

      const plane = space?.find(
        (s): s is Space.Plane => s.id === planeID && s.kind === 'plane',
      );

      if (!plane || plane.positions.length < 3) {
        setStatus('error');

        return;
      }

      setStatus('loading');

      try {
        const [result] = await Promise.all([
          routineRunnerHandle.runVisionMethod({
            method: 'locate',
            locateMethod: stepConfiguration.args.method,
            camera: stepConfiguration.args.camera,
            regionOfInterest: stepConfiguration.args.regionOfInterest,
            plane: [
              cartesianPositionFromPose(plane.positions[0].pose),
              cartesianPositionFromPose(plane.positions[1].pose),
              cartesianPositionFromPose(plane.positions[2].pose),
            ],
            resultsLimit: stepConfiguration.args.resultsLimit,
            transform: applyTransform ? stepConfiguration.args.transform : null,
            accuracyCalibration: null,
          }),
          // minimum duration to avoid flashing of 'loading' state
          wait(500),
        ]);

        if (result.method === 'locate') {
          setWidgetState({
            item: {
              ...spaceItem,
              positions: result.results.map(({ pose, blob }) => ({
                jointAngles: NULL_JOINT_ANGLES,
                pose,
                name: `Score: ${blob.score}%`,
              })),
            },
          });

          setStatus('loaded');

          return;
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    }

    setStatus('error');
  };

  useEffect(() => {
    setStatus('init');
  }, [applyTransform]);

  useEffect(() => {
    if (status === 'init') {
      getResults();
    }
  });

  return {
    status,
    getResults,
  };
}
