import { shallow } from 'zustand/shallow';

import { NavigationBar } from '@sb/design-system';
import type { Space } from '@sb/routine-runner';
import { CameraSettings, CameraStream } from '@sbrc/components/camera';

import WidgetView from '../../../widget-panel/WidgetView';
import { HeaderBackButton } from '../../HeaderBackButton';
import { useSpaceWidgetStore } from '../../useSpaceWidgetStore';

import { getUpdateItem } from './getUpdateItem';

interface AccuracyCalibrationCameraSettingsProps {
  spaceItem: Space.Plane;
}

export function AccuracyCalibrationCameraSettings({
  spaceItem,
}: AccuracyCalibrationCameraSettingsProps) {
  const [setWidgetItem, setWidgetCurrentView] = useSpaceWidgetStore(
    (s) => [s.setWidgetItem, s.setWidgetCurrentView],
    shallow,
  );

  return (
    <WidgetView>
      <NavigationBar
        className="tw-pl-8 tw-pr-8"
        contentLeft={
          <HeaderBackButton
            onBack={() => setWidgetCurrentView('accuracyCalibration')}
          />
        }
      >
        Accuracy calibration
      </NavigationBar>

      <div className="tw-mx-16 tw-mb-16">
        <CameraStream className="tw-rounded-10 tw-surface-elevated tw-shadow-30 tw-overflow-hidden" />
      </div>

      <div className="tw-flex tw-flex-col tw-flex-1 tw-overflow-auto tw-px-16 tw-mb-16">
        <CameraSettings
          value={spaceItem.calibrationCameraSettings}
          onChange={(calibrationCameraSettings) =>
            setWidgetItem(
              { ...spaceItem, calibrationCameraSettings },
              getUpdateItem,
            )
          }
          doUpdateCameraSettings
        />
      </div>
    </WidgetView>
  );
}
