import cx from 'classnames';
import { useId } from 'react';
import { shallow } from 'zustand/shallow';

import { NavigationBar, NumberInput } from '@sb/design-system';
import type { Space } from '@sb/routine-runner';
import { FileInput } from '@sb/ui/components';
import { useToast } from '@sbrc/hooks';
import { uploadFileToStorage } from '@sbrc/services';

import WidgetView from '../../widget-panel/WidgetView';
import { HeaderBackButton } from '../HeaderBackButton';
import { useSpaceWidgetStore } from '../useSpaceWidgetStore';

interface EditEnvironmentObject {
  spaceItem: Space.EnvironmentObject;
}

export function EditEnvironmentObject({ spaceItem }: EditEnvironmentObject) {
  const [widgetState, setWidgetState] = useSpaceWidgetStore(
    (s) => [s.widgetState, s.setWidgetState] as const,
    shallow,
  );

  const { setToast } = useToast();

  const save = (changes: Partial<Space.EnvironmentObject>) => {
    const item = { ...spaceItem, ...changes };

    setWidgetState({
      ...widgetState,
      item,
      updateItem: item,
    });
  };

  const inputIDPrefix = useId();

  return (
    <WidgetView>
      <NavigationBar
        className="tw-pl-8 tw-pr-8"
        contentLeft={<HeaderBackButton />}
      >
        {spaceItem.name}
      </NavigationBar>

      <form
        className={cx('tw-flex', 'tw-flex-col', 'tw-mx-16', 'tw-mb-16')}
        onSubmit={(e) => e.preventDefault()}
      >
        <p className={cx('tw-text-15', 'tw-text-label-tertiary')}>
          GLTF/GLB format is recommended for import, but FBX, OBJ, and STL are
          also supported. You can also use the Polycam iOS app to scan your
          environment and export GLTF format (works best on iPhone/iPad Pro)
        </p>

        <label className="tw-heading-40" htmlFor={`${inputIDPrefix}-file`}>
          File
        </label>

        <FileInput
          id={`${inputIDPrefix}-file`}
          accept=".fbx,.glb,.gltf,.obj,.stl"
          downloadURL={spaceItem.fileURL ?? undefined}
          fileName={spaceItem.fileName ?? undefined}
          uploadFile={async (file) => {
            try {
              const response = await uploadFileToStorage({ file });
              save({ fileURL: response.downloadURL, fileName: file.name });
            } catch (e) {
              setToast({
                kind: 'error',
                message: `File upload failed ${e?.message}`,
              });
            }
          }}
        />

        <label className="tw-heading-40" htmlFor={`${inputIDPrefix}-scale`}>
          Scale
        </label>

        <NumberInput
          value={spaceItem.scale ?? NaN}
          onChange={(value) =>
            save({ scale: Number.isNaN(value) ? undefined : value })
          }
        />
      </form>
    </WidgetView>
  );
}
