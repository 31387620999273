import { useMemo } from 'react';

import type { Space } from '@sb/routine-runner';

import { UNSAVED_POSITION } from '../../types';
import { Puck } from '../Puck';

interface VisualizePositionsProps {
  positions: Space.Position[];
  currentPositionIndex?: number;
}

const MAX_TO_SHOW = 50;

export function VisualizePositions({
  positions,
  currentPositionIndex,
}: VisualizePositionsProps) {
  const puckPositions = useMemo(() => {
    const mappedPositions = positions
      .map((position, index) => ({
        key: index,
        label: `${index}`,
        position,
        isCurrentPosition: index === currentPositionIndex,
      }))
      // filter out unsaved positions
      .filter(({ position }) => position !== UNSAVED_POSITION);

    const downsampleFactor =
      Math.floor(mappedPositions.length / MAX_TO_SHOW) + 1;

    if (downsampleFactor === 1) {
      return mappedPositions;
    }

    // lots of positions, just show some
    return mappedPositions.filter(
      ({ isCurrentPosition }, i) =>
        i % downsampleFactor === 0 || isCurrentPosition,
    );
  }, [positions, currentPositionIndex]);

  return (
    <>
      {puckPositions.map(({ key, label, position, isCurrentPosition }) => {
        return (
          <Puck
            key={key}
            position={position.pose}
            label={label}
            isGray={!isCurrentPosition}
          />
        );
      })}
    </>
  );
}
