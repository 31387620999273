import { Html } from '@react-three/drei';
import { useThree } from '@react-three/fiber';
import cx from 'classnames';
import { useEffect } from 'react';

import { useComponentColorClassName } from '@sb/design-system';
import type { CartesianPosition } from '@sb/geometry';

import { PUCK_COLOR_CLASSES } from '../puckColor';

interface PuckProps {
  position?: CartesianPosition;
  label: string;
  isGray?: boolean;
}

export function Puck({ position, label, isGray }: PuckProps) {
  // need to invalidate on mount, otherwise (occasionally) the HTML labels don't show immediately
  const invalidate = useThree((state) => state.invalidate);
  useEffect(() => invalidate(), [invalidate]);

  const className = useComponentColorClassName(
    isGray ? 'Gray' : 'Default',
    PUCK_COLOR_CLASSES,
  );

  if (!position || Number.isNaN(position.x + position.y + position.z)) {
    return null;
  }

  return (
    <Html
      transform
      sprite
      distanceFactor={1}
      position={[position.x, position.y, position.z]}
      className={cx(
        'tw-text-15',
        'tw-border',
        'tw-border-white',
        'tw-w-[30px]',
        'tw-h-[30px]',
        'tw-rounded-full',
        'tw-flex',
        'tw-items-center',
        'tw-justify-center',
        className,
      )}
      pointerEvents="none"
      occlude="blending"
      geometry={<sphereGeometry args={[0.037]} />}
    >
      {label}
    </Html>
  );
}
