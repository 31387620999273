import { useState } from 'react';

import {
  NavigationBar,
  SettingsGroup,
  SettingsGroupNavigationItem,
} from '@sb/design-system';
import {
  ZERO_ROTATION,
  applyCompoundPose,
  cameraPoseFromWristPose,
  constructPlaneFromPose,
  convertVector3ToPosition,
} from '@sb/geometry';
import type { CartesianPose } from '@sb/geometry';
import type { Space } from '@sb/routine-runner';
import { six } from '@sb/utilities';
import { SelectCamera } from '@sbrc/components/camera';
import { useRoutineRunnerHandle } from '@sbrc/hooks';

import WidgetView from '../../../widget-panel/WidgetView';
import { HeaderBackButton } from '../../HeaderBackButton';
import { useSpaceWidgetStore } from '../../useSpaceWidgetStore';

import { CreateWithCameraModal } from './CreateWithCameraModal';
import { EditPlaneMoreMenu } from './EditPlaneMoreMenu';
import { getUpdateItem } from './getUpdateItem';

interface CreateWithCameraProps {
  spaceItem: Space.Plane;
}

export function CreateWithCamera({ spaceItem }: CreateWithCameraProps) {
  const routineRunnerHandle = useRoutineRunnerHandle({});

  const setWidgetItem = useSpaceWidgetStore((s) => s.setWidgetItem);

  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <WidgetView>
        <NavigationBar
          className="tw-pl-8 tw-pr-8"
          contentLeft={<HeaderBackButton />}
          contentRight={<EditPlaneMoreMenu />}
        >
          {spaceItem.name}
        </NavigationBar>

        <p className="tw-mx-16 tw-text-15 tw-text-label-secondary">
          This plane can be used in vision steps like Locate to help the robot
          find what it sees with the camera
        </p>

        <SettingsGroup className="tw-m-16">
          <SelectCamera value="wristCamera" onChange={() => {}} />
          <SettingsGroupNavigationItem
            label="Capture"
            onClick={() => setIsModalOpen(true)}
          />
        </SettingsGroup>
      </WidgetView>

      <CreateWithCameraModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onCapture={(camera2chessboard) => {
          const wristPose =
            routineRunnerHandle.getState()?.kinematicState.wristPose;

          if (!wristPose) {
            return;
          }

          const cameraPose = cameraPoseFromWristPose(wristPose);

          const planePose = applyCompoundPose(camera2chessboard, cameraPose);

          const { origin, plusX, plusY } = constructPlaneFromPose(planePose);

          const positions = [origin, plusX, plusY].map((vec) => {
            const asPose: CartesianPose = {
              ...ZERO_ROTATION,
              ...convertVector3ToPosition(vec),
            };

            return {
              pose: asPose,
              jointAngles: six(0),
            };
          });

          setWidgetItem(
            { ...spaceItem, positions, capturedWithCameraKind: 'wristCamera' },
            getUpdateItem,
          );
        }}
      />
    </>
  );
}
