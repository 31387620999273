import {
  Menu,
  MenuItem,
  MenuSeparator,
  NavigationBarMenuButton,
} from '@sb/design-system';
import type { Space } from '@sb/routine-runner';
import { useFeatureFlag } from '@sbrc/hooks';

import { getSpaceItemIconKind } from './getSpaceItemIconKind';
import { getSpaceItemKindName } from './getSpaceItemKindName';
import type { WidgetState } from './types';

interface AddSpaceItemProps {
  setWidgetState: (state: WidgetState) => void;
}

export function AddSpaceItem({ setWidgetState }: AddSpaceItemProps) {
  const enableEnvironmentObjects = useFeatureFlag('environmentObjects');
  const enablePlanes = useFeatureFlag('planes');

  const menuItem = (itemKind: Space.ItemKind) => {
    return (
      <MenuItem
        key={itemKind}
        onClick={() => setWidgetState({ createItemKind: itemKind })}
        iconKind={getSpaceItemIconKind(itemKind)}
      >
        {getSpaceItemKindName(itemKind)}
      </MenuItem>
    );
  };

  return (
    <NavigationBarMenuButton
      content={
        <Menu>
          {menuItem('singlePosition')}
          {menuItem('freeformPositionList')}
          {menuItem('gridPositionList')}
          {enablePlanes && (
            <>
              <MenuSeparator />
              {menuItem('plane')}
            </>
          )}
          {enableEnvironmentObjects && (
            <>
              <MenuSeparator />
              {menuItem('environmentObject')}
            </>
          )}
        </Menu>
      }
      iconKind="plus"
    />
  );
}
