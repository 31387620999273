import cx from 'classnames';

import { SettingsGroupItem } from '@sb/design-system';
import type { Space } from '@sb/routine-runner';

import { PositionItemId } from './PositionItemId';
import { SyncButton } from './SyncButton';

interface ReadonlyPositionItemProps {
  id: number;
  position: Space.Position;
  isSelected: boolean;
  onSelect: () => void;
}

export function ReadonlyPositionItem({
  id,
  position,
  isSelected,
  onSelect,
}: ReadonlyPositionItemProps) {
  return (
    <SettingsGroupItem
      isSeparated
      className={cx(isSelected && 'tw-outline-selected')}
      data-testid={`position-list-item-${id}`}
      onMouseDown={onSelect}
      onTouchStart={onSelect}
      role="button"
    >
      <PositionItemId id={id} isSelected={isSelected} />

      <span className="tw-flex-1">{position.name}</span>

      <SyncButton position={position} />
    </SettingsGroupItem>
  );
}
