import { useMemo } from 'react';
import { Vector3 } from 'three';

import {
  NavigationBar,
  SettingsGroup,
  SettingsGroupItem,
} from '@sb/design-system';
import { getPlaneOrientation } from '@sb/geometry';
import type { Space } from '@sb/routine-runner';
import { convertEulerPose } from '@sbrc/utils';

import WidgetView from '../../../widget-panel/WidgetView';
import { HeaderBackButton } from '../../HeaderBackButton';
import { useSpaceWidgetStore } from '../../useSpaceWidgetStore';

interface PlaneInfoProps {
  spaceItem: Space.Plane;
}

export function PlaneInfo({ spaceItem }: PlaneInfoProps) {
  const setWidgetCurrentView = useSpaceWidgetStore(
    (s) => s.setWidgetCurrentView,
  );

  const origin = spaceItem.positions[0]?.pose;
  const plusX = spaceItem.positions[1]?.pose;
  const plusY = spaceItem.positions[2]?.pose;

  const planePose = useMemo(() => {
    if (!origin || !plusX || !plusY) {
      return undefined;
    }

    const plane = {
      origin: new Vector3(origin.x, origin.y, origin.z),
      plusX: new Vector3(plusX.x, plusX.y, plusX.z),
      plusY: new Vector3(plusY.x, plusY.y, plusY.z),
    };

    const quaternion = getPlaneOrientation(plane);

    return convertEulerPose.fromCartesian({
      x: origin.x,
      y: origin.y,
      z: origin.z,
      i: quaternion.x,
      j: quaternion.y,
      k: quaternion.z,
      w: quaternion.w,
    });
  }, [origin, plusX, plusY]);

  return (
    <WidgetView>
      <NavigationBar
        className="tw-pl-8 tw-pr-8"
        contentLeft={
          <HeaderBackButton onBack={() => setWidgetCurrentView(undefined)} />
        }
      >
        {spaceItem.name}
      </NavigationBar>

      <SettingsGroup className="tw-m-16">
        <SettingsGroupItem>
          <span className="tw-flex-1 tw-text-red">Origin X</span>
          <span>{planePose?.X.toFixed(3)}</span>
          <span className="tw-text-label-secondary">m</span>
        </SettingsGroupItem>

        <SettingsGroupItem>
          <span className="tw-flex-1 tw-text-green">Origin Y</span>
          <span>{planePose?.Y.toFixed(3)}</span>
          <span className="tw-text-label-secondary">m</span>
        </SettingsGroupItem>

        <SettingsGroupItem>
          <span className="tw-flex-1 tw-text-blue">Origin Z</span>
          <span>{planePose?.Z.toFixed(3)}</span>
          <span className="tw-text-label-secondary">m</span>
        </SettingsGroupItem>

        <SettingsGroupItem>
          <span className="tw-flex-1 tw-text-red">Roll</span>
          <span>{planePose?.Rx.toFixed(2)}°</span>
        </SettingsGroupItem>

        <SettingsGroupItem>
          <span className="tw-flex-1 tw-text-green">Pitch</span>
          <span>{planePose?.Ry.toFixed(2)}°</span>
        </SettingsGroupItem>

        <SettingsGroupItem>
          <span className="tw-flex-1 tw-text-blue">Yaw</span>
          <span>{planePose?.Rz.toFixed(2)}°</span>
        </SettingsGroupItem>
      </SettingsGroup>
    </WidgetView>
  );
}
