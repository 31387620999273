import cx from 'classnames';
import { clamp } from 'lodash';
import React, { useId, useRef, useState } from 'react';
import { shallow } from 'zustand/shallow';

import {
  Button,
  NavigationBar,
  NumberInput,
  SettingsGroup,
  SettingsGroupItem,
} from '@sb/design-system';
import type { Space } from '@sb/routine-runner';
import { useRoutineRunnerHandle, useToast } from '@sbrc/hooks';
import { getRobotPosition } from '@sbrc/utils';

import WidgetView from '../../../widget-panel/WidgetView';
import { HeaderBackButton } from '../../HeaderBackButton';
import { UNSAVED_POSITION } from '../../types';
import { useSpaceWidgetStore } from '../../useSpaceWidgetStore';
import { LabeledPositionItem } from '../position-item/LabeledPositionItem';

import type { GridCorner } from './corners';
import { CORNERS } from './corners';
import { generateGridPositions } from './generateGridPositions';
import { GridDiagram } from './GridDiagram';

const MIN_ITEMS = 1;
const MAX_ITEMS = 100;

interface GridSetupProps {
  spaceItem: Space.GridPositionList;
}

export function GridSetup({ spaceItem }: GridSetupProps) {
  const [isVizbot, widgetState, setWidgetState] = useSpaceWidgetStore(
    (s) => [s.isVizbot, s.widgetState, s.setWidgetState] as const,
    shallow,
  );

  const routineRunnerHandle = useRoutineRunnerHandle({ isVizbot });
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const abortSubmitRef = useRef<AbortController | null>(null);

  const [positionsValidatedCount, setPositionsValidatedCount] =
    useState<number>(0);

  const { setToast } = useToast();

  const inputID = useId();

  const isNew = spaceItem.positions.length === 0;

  const handleSaveCorner = (corner: GridCorner) => () => {
    const robotPosition = getRobotPosition(routineRunnerHandle);

    if (robotPosition) {
      const item = {
        ...spaceItem,
        [corner]: robotPosition,
      };

      setWidgetState({
        ...widgetState,
        item,
        updateItem: item,
      });
    }
  };

  const handleClearCorner = (corner: GridCorner) => () => {
    const item = {
      ...spaceItem,
      [corner]: null,
    };

    setWidgetState({ ...widgetState, item });
  };

  const handleChangeDimension =
    (dimension: 'numRows' | 'numColumns') => (value: number) => {
      const item = {
        ...spaceItem,
        [dimension]: clamp(value, MIN_ITEMS, MAX_ITEMS),
      };

      setWidgetState({ ...widgetState, item });
    };

  const handleSubmit: React.FormEventHandler = async (event) => {
    event.preventDefault();

    setPositionsValidatedCount(0);
    setIsSubmitting(true);

    const abortController = new AbortController();
    abortSubmitRef.current?.abort();
    abortSubmitRef.current = abortController;

    try {
      const item = await generateGridPositions(
        spaceItem,
        getRobotPosition(routineRunnerHandle),
        () => setPositionsValidatedCount((n) => n + 1),
        abortController.signal,
      );

      if (!abortController.signal.aborted) {
        setWidgetState({
          ...widgetState,
          item,
          updateItem: item,
          currentPositionIndex: undefined,
        });
      }
    } catch (error) {
      if (!abortController.signal.aborted) {
        setToast({ kind: 'error', message: error.message });
      }
    }

    setPositionsValidatedCount(0);
    setIsSubmitting(false);
  };

  const handleGoBack = () => {
    abortSubmitRef.current?.abort();

    if (isNew) {
      // back to space list
      setWidgetState({});
    } else {
      // back to position list
      setWidgetState({ ...widgetState, currentView: undefined });
    }
  };

  const unsavedCorners = CORNERS.map(({ corner }) => corner).filter(
    (corner) => !spaceItem[corner],
  );

  const areThreeCornersSaved = unsavedCorners.length === 1;

  const isSubmitDisabled = !areThreeCornersSaved || isSubmitting;

  return (
    <WidgetView>
      <NavigationBar
        className="tw-pl-8 tw-pr-8"
        contentLeft={<HeaderBackButton onBack={handleGoBack} />}
      >
        {spaceItem.name}
      </NavigationBar>

      <form className={cx('tw-contents')}>
        <div
          className={cx(
            'tw-flex',
            'tw-flex-col',
            'tw-flex-1',
            'tw-gap-16',
            'tw-overflow-auto',
            'tw-px-16',
          )}
        >
          <section className={cx('tw-text-15')}>
            <GridDiagram
              className={cx(
                'tw-float-right',
                'tw-w-[155px]',
                'tw-ml-8',
                'tw-mt-16',
              )}
              numColumns={spaceItem.numColumns}
              numRows={spaceItem.numRows}
              unsavedCorners={unsavedCorners}
            />

            <h5 className="tw-heading-40">Size & position</h5>

            <p className="tw-mb-8">
              Set three corner positions to specify the size, location and
              orientation of the grid.
            </p>

            <p>
              To do this: for each corner, move the arm to the corner position
              then return to this panel and tap the “Set” button.
            </p>
          </section>

          <SettingsGroup>
            {CORNERS.map(({ corner, label }) => {
              const isUnsaved = unsavedCorners.includes(corner);

              return (
                <LabeledPositionItem
                  key={corner}
                  position={spaceItem[corner] ?? UNSAVED_POSITION}
                  label={label}
                  isSubmitting={isSubmitting}
                  isInferred={isUnsaved && areThreeCornersSaved}
                  onClear={handleClearCorner(corner)}
                  onSave={handleSaveCorner(corner)}
                />
              );
            })}
          </SettingsGroup>

          <section>
            <h5 className="tw-heading-40">Layout</h5>

            <SettingsGroup>
              <SettingsGroupItem>
                <label
                  htmlFor={`${inputID}rows`}
                  className={cx(
                    'tw-flex',
                    'tw-flex-col',
                    'tw-py-10',
                    'tw-flex-1',
                  )}
                >
                  <span>Rows</span>
                  <span className={cx('tw-text-label-tertiary', 'tw-text-13')}>
                    (between A & B)
                  </span>
                </label>
                <NumberInput
                  id={`${inputID}rows`}
                  variant="Gray"
                  alignment="Center"
                  value={spaceItem.numRows}
                  onChange={handleChangeDimension('numRows')}
                  disabled={isSubmitting}
                  decimalPlaces={0}
                  step={1}
                  min={MIN_ITEMS}
                  max={MAX_ITEMS}
                  className="tw-w-128"
                />
              </SettingsGroupItem>

              <SettingsGroupItem>
                <label
                  htmlFor={`${inputID}cols`}
                  className={cx(
                    'tw-flex',
                    'tw-flex-col',
                    'tw-py-10',
                    'tw-flex-1',
                  )}
                >
                  <span>Columns</span>
                  <span className={cx('tw-text-label-tertiary', 'tw-text-13')}>
                    (between A & D)
                  </span>
                </label>

                <NumberInput
                  id={`${inputID}cols`}
                  variant="Gray"
                  size={32}
                  alignment="Center"
                  value={spaceItem.numColumns}
                  onChange={handleChangeDimension('numColumns')}
                  disabled={isSubmitting}
                  decimalPlaces={0}
                  step={1}
                  min={MIN_ITEMS}
                  max={MAX_ITEMS}
                  className="tw-w-128"
                />
              </SettingsGroupItem>
            </SettingsGroup>
          </section>
        </div>

        <Button
          type="submit"
          variant="Filled"
          disabled={isSubmitDisabled}
          onClick={handleSubmit}
          className={cx('tw-rounded-6', 'tw-m-16')}
        >
          {isSubmitting
            ? `Checking Positions ${positionsValidatedCount} / ${
                spaceItem.numColumns * spaceItem.numRows
              }`
            : 'Generate Position List'}
        </Button>
      </form>
    </WidgetView>
  );
}
