import { useCallback, useState } from 'react';
import { shallow } from 'zustand/shallow';

import { ZERO_POSE, cartesianPositionFromPose } from '@sb/geometry';
import type { RunLocateResult } from '@sb/routine-runner';
import { DEFAULT_REGION_OF_INTEREST, type Space } from '@sb/routine-runner';
import { wait } from '@sb/utilities';
import { useRoutineRunnerHandle } from '@sbrc/hooks';

import { useSpaceWidgetStore } from '../../useSpaceWidgetStore';

import { isCalibrationEntryLocateResult } from './isCalibrationEntryLocateResult';
// Adjust the path as per your project structure
type ResultStatus = 'init' | 'loading' | 'loaded' | 'error';

export function useAccuracyCalibrationLocateResults(spaceItem: Space.Plane) {
  const [isVizbot, setWidgetItem, setCurrentPositionIndex] =
    useSpaceWidgetStore(
      (s) => [s.isVizbot, s.setWidgetItem, s.setCurrentPositionIndex],
      shallow,
    );

  const routineRunnerHandle = useRoutineRunnerHandle({ isVizbot });

  const [locateStatus, setLocateStatus] = useState<ResultStatus>('init');

  const setCurrentLocateResult = (
    result?: RunLocateResult['results'][number] | null,
  ) => {
    const item: Space.Plane = {
      ...spaceItem,
      calibrationEntries: spaceItem.calibrationEntries
        // remove old locate results
        .filter((entry) => !isCalibrationEntryLocateResult(entry)),
    };

    if (result) {
      item.calibrationEntries.push({
        rawPose: result.pose,
        calibratedPose: ZERO_POSE,
        offset: ZERO_POSE,
      });
    }

    setWidgetItem(item);

    if (result) {
      setCurrentPositionIndex(item.calibrationEntries.length - 1);
    }
  };

  const getLocateResults = useCallback(async (): Promise<
    RunLocateResult['results']
  > => {
    setLocateStatus('loading');

    try {
      const [result] = await Promise.all([
        routineRunnerHandle.runVisionMethod({
          method: 'locate',
          locateMethod: {
            kind: 'ChessboardDetection2D',
          },
          camera: spaceItem.calibrationCameraSettings,
          regionOfInterest: DEFAULT_REGION_OF_INTEREST,
          plane: [
            cartesianPositionFromPose(spaceItem.positions[0].pose),
            cartesianPositionFromPose(spaceItem.positions[1].pose),
            cartesianPositionFromPose(spaceItem.positions[2].pose),
          ],
          resultsLimit: 10,
          transform: null,
          accuracyCalibration: null,
        }),
        // minimum duration to avoid flashing of 'loading' state
        wait(500),
      ]);

      if (result.method === 'locate') {
        setLocateStatus('loaded');

        return result.results;
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }

    setLocateStatus('error');

    return [];
  }, [spaceItem, routineRunnerHandle]);

  return {
    locateStatus,
    getLocateResults,
    setCurrentLocateResult,
  };
}
