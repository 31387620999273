import type { IconKind } from '@sb/design-system';
import type { Space } from '@sb/routine-runner';

export function getSpaceItemIconKind(itemKind: Space.ItemKind): IconKind {
  switch (itemKind) {
    case 'freeformPositionList':
      return 'circleHexagonPath';
    case 'singlePosition':
      return 'scope';
    case 'gridPositionList':
      return 'squareGrid3x3';
    case 'visionPositionList':
      return 'circleHexagonPathCamera';
    case 'plane':
      return 'plane';
    case 'environmentObject':
      return 'box';
    default: {
      // ensure type exhaustive
      itemKind satisfies never;

      return 'viewfinderBox';
    }
  }
}
