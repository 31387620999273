import type { Robot } from '@sb/remote-control/types';

// TODO: https://linear.app/standardbots/issue/APPS-3332/add-missing-safety-ports-in-arm-control-bot
export function getSafetyPorts(): Array<Robot.IOPort> {
  const safetyIOPorts: Array<Robot.IOPort> = [
    {
      highSignalName: 'Run',
      kind: 'Safety',
      lowSignalName: 'Stop',
      name: 'Safety In (circle)',
      port: 1,
      label: 'S in',
    },
    {
      highSignalName: 'Run',
      kind: 'Safety',
      lowSignalName: 'Stop',
      name: 'E-Stop In (square)',
      port: 2,
      label: 'ES IN',
    },
  ];

  return safetyIOPorts;
}
