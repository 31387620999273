import { shallow } from 'zustand/shallow';

import { Button } from '@sb/design-system';
import type { Space } from '@sb/routine-runner';

import { useSpaceWidgetStore } from '../../useSpaceWidgetStore';
import { EditPositionList } from '../EditPositionList';

import { GridSetup } from './GridSetup';

interface EditGridPrositionListProps {
  spaceItem: Space.GridPositionList;
}

export function EditGridPositionList({
  spaceItem,
}: EditGridPrositionListProps) {
  const [currentView, setWidgetCurrentView] = useSpaceWidgetStore(
    (s) => [s.widgetState.currentView, s.setWidgetCurrentView] as const,
    shallow,
  );

  const isNew = spaceItem.positions.length === 0;

  if (isNew || currentView === 'gridSetup') {
    return <GridSetup spaceItem={spaceItem} />;
  }

  // show position list
  return (
    <EditPositionList spaceItem={spaceItem}>
      <Button
        variant="Filled"
        onClick={() => setWidgetCurrentView('gridSetup')}
        className="tw-rounded-6"
      >
        Regenerate Grid
      </Button>
    </EditPositionList>
  );
}
