import cx from 'classnames';

import { useComponentColorClassName } from '@sb/design-system';

import { PUCK_COLOR_CLASSES } from '../../puckColor';

export interface PositionItemIdProps {
  id: React.ReactNode;
  isSelected?: boolean;
}

export function PositionItemId({ id, isSelected }: PositionItemIdProps) {
  const puckClassName = useComponentColorClassName(
    isSelected ? 'Default' : 'Gray',
    PUCK_COLOR_CLASSES,
  );

  return (
    <div
      className={cx(
        puckClassName,
        'tw-text-15',
        'tw-min-w-[22px]',
        'tw-h-[22px]',
        'tw-rounded-full',
        'tw-flex',
        'tw-items-center',
        'tw-justify-center',
      )}
    >
      {id}
    </div>
  );
}
