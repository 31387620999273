import cx from 'classnames';
import { shallow } from 'zustand/shallow';

import {
  Icon,
  NavigationBar,
  SettingsGroupNavigationItem,
} from '@sb/design-system';
import { useFeatureFlag } from '@sbrc/hooks';

import WidgetView from '../widget-panel/WidgetView';

import { AddSpaceItem } from './AddSpaceItem';
import { getSpaceItemIconKind } from './getSpaceItemIconKind';
import { SpaceItemSettings } from './SpaceItemSettings';
import { useSpaceWidgetStore } from './useSpaceWidgetStore';

export function SpaceList() {
  const [setWidgetState, space = [], routineName = ''] = useSpaceWidgetStore(
    (s) => [s.setWidgetState, s.routine?.space, s.routine?.name],
    shallow,
  );

  const enableEnvironmentObjects = useFeatureFlag('environmentObjects');

  return (
    <WidgetView>
      <NavigationBar
        className="tw-pl-8 tw-pr-8"
        contentRight={<AddSpaceItem setWidgetState={setWidgetState} />}
      >
        Space
      </NavigationBar>

      {space.length === 0 ? (
        <>
          <Icon
            kind="viewfinderBox"
            className={cx(
              'tw-icon-42',
              'tw-text-label-secondary',
              'tw-mx-auto',
            )}
          />
          <p
            className={cx(
              'tw-flex-none',
              'tw-text-15',
              'tw-text-label-tertiary',
              'tw-mt-12',
              'tw-m-32',
              'tw-text-center',
            )}
          >
            Space allows you to define positions and objects around the robot
            that can be referenced in routines. To add items here, tap the{' '}
            <strong>“Add”</strong> button above.
          </p>
        </>
      ) : (
        <>
          <div
            className={cx(
              'tw-flex-1',
              'tw-overflow-auto',
              'tw-mx-16',
              'tw-flex',
              'tw-flex-col',
              'tw-gap-8',
            )}
          >
            {space.map((item) => {
              const itemName = (
                <>
                  <Icon
                    kind={getSpaceItemIconKind(item.kind)}
                    className={cx(
                      'tw-icon-22',
                      'tw-mr-12',
                      'tw-text-label-secondary',
                    )}
                  />
                  <div
                    className={cx(
                      'tw-flex',
                      'tw-flex-col',
                      'tw-py-[11px]',
                      'tw-text-ellipsis',
                    )}
                  >
                    <p>{item.name}</p>
                    <p className={cx('tw-text-13', 'tw-text-label-secondary')}>
                      {item.description}
                    </p>
                  </div>
                </>
              );

              const isDisabled =
                item.kind === 'environmentObject' && !enableEnvironmentObjects;

              const isSettingsHidden =
                isDisabled || item.kind === 'visionPositionList';

              return (
                <SettingsGroupNavigationItem
                  isSeparated
                  key={item.id}
                  onClick={() => setWidgetState({ item })}
                  label={itemName}
                  disabled={isDisabled}
                >
                  {!isSettingsHidden && <SpaceItemSettings spaceItem={item} />}
                </SettingsGroupNavigationItem>
              );
            })}
          </div>

          <p
            className={cx(
              'tw-flex-none',
              'tw-text-13',
              'tw-text-label-tertiary',
              'tw-m-16',
              'tw-text-ellipsis',
            )}
          >
            {space.length}
            {space.length === 1 ? ' item' : ' items'}
            {` from “${routineName}”`}
          </p>
        </>
      )}
    </WidgetView>
  );
}
