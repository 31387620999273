import { useThree } from '@react-three/fiber';
import { useEffect, useMemo, useRef } from 'react';
import type { Mesh, Vector3 } from 'three';
import {
  BufferAttribute,
  BufferGeometry,
  CanvasTexture,
  DoubleSide,
  Vector2,
} from 'three';

import {
  cameraPoseFromWristPose,
  deprojectOntoPlane,
  type Plane,
} from '@sb/geometry';
import { useCameraIntrinsics } from '@sbrc/components/camera';
import { getCameraStream } from '@sbrc/components/camera/stream/getCameraStream';
import { useRoutineRunnerHandle } from '@sbrc/hooks';
import { arePosesEqual } from '@sbrc/utils';

import { useSpaceWidgetStore } from '../../useSpaceWidgetStore';

const TEXTURE_SIZE = 200;

// The FOV quad is made up of GEOMETRY_SEGMENTS * GEOMETRY_SEGMENTS segments
// each segment is made up of two triangles - ◩ & ◪
// GEOMETRY_SEGMENTS > 1 so that affine mapping of the camera image on to it looks okay
// see https://en.wikipedia.org/wiki/Texture_mapping#Affine_texture_mapping
const GEOMETRY_SEGMENTS = 9;

const GEOMETRY_UVS = Array.from(
  { length: GEOMETRY_SEGMENTS },
  (_, i) => i / (GEOMETRY_SEGMENTS - 1),
);

interface VisualizeCameraFOVProps {
  plane: Plane;
}

export function VisualizeCameraFOV({ plane }: VisualizeCameraFOVProps) {
  const isVizbot = useSpaceWidgetStore((s) => s.isVizbot);
  const routineRunnerHandle = useRoutineRunnerHandle({ isVizbot });
  const intrinsics = useCameraIntrinsics();

  const meshRef = useRef<Mesh>(null);

  const invalidate = useThree((state) => state.invalidate);

  useEffect(() => {
    const unsubscribe = routineRunnerHandle.onStateChange(
      (s) => s.kinematicState.wristPose,
      arePosesEqual,
      (wristPose) => {
        const mesh = meshRef.current;

        if (!intrinsics || !mesh) {
          return;
        }

        const cameraPose = cameraPoseFromWristPose(wristPose);

        const pointsAndUVs: Array<{ point: Vector3; u: number; v: number }> =
          [];

        const calculatePointsOnPlane = () => {
          for (const u of GEOMETRY_UVS) {
            for (const v of GEOMETRY_UVS) {
              const point = deprojectOntoPlane({
                pixelCoordinates: new Vector2(
                  intrinsics.width * u,
                  intrinsics.height * (1 - v),
                ),
                plane,
                cameraPose,
                cameraIntrinsics: intrinsics,
              });

              if (!point) {
                pointsAndUVs.length = 0;

                return;
              }

              pointsAndUVs.push({ point, u, v });
            }
          }
        };

        calculatePointsOnPlane();

        if (pointsAndUVs.length === 0) {
          mesh.geometry = new BufferGeometry();
        } else {
          const points: Vector3[] = [];
          const uvs: number[] = [];

          const pushPointAndUV = (x: number, y: number) => {
            const pointAndUV = pointsAndUVs[y + x * GEOMETRY_SEGMENTS];
            points.push(pointAndUV.point);
            uvs.push(pointAndUV.u, pointAndUV.v);
          };

          // loop through the segments
          for (let x = 0; x < GEOMETRY_SEGMENTS - 1; x += 1) {
            for (let y = 0; y < GEOMETRY_SEGMENTS - 1; y += 1) {
              // points in triangle 1 of segment ◩
              pushPointAndUV(x, y);
              pushPointAndUV(x + 1, y);
              pushPointAndUV(x, y + 1);
              // points in triangle 2 of segment ◪
              pushPointAndUV(x, y + 1);
              pushPointAndUV(x + 1, y);
              pushPointAndUV(x + 1, y + 1);
            }
          }

          mesh.geometry = new BufferGeometry().setFromPoints(points);

          mesh.geometry.setAttribute(
            'uv',
            new BufferAttribute(new Float32Array(uvs), 2),
          );
        }

        invalidate();
      },
    );

    return unsubscribe;
  }, [plane, intrinsics, routineRunnerHandle, invalidate]);

  const canvas = useMemo(() => document.createElement('canvas'), []);
  const texture = useMemo(() => new CanvasTexture(canvas), [canvas]);

  useEffect(() => {
    let rafID = 0;
    const ctx = canvas.getContext('2d');

    if (!ctx) {
      return undefined;
    }

    let prevBitmap: ImageBitmap | null | undefined;

    const runRAF = () => {
      rafID = requestAnimationFrame(() => {
        const cameraStream = getCameraStream();

        const bitmap = cameraStream.error ? null : cameraStream.imageBitmap;

        if (bitmap !== prevBitmap) {
          prevBitmap = bitmap;

          if (canvas.width !== TEXTURE_SIZE || canvas.height !== TEXTURE_SIZE) {
            canvas.width = TEXTURE_SIZE;
            canvas.height = TEXTURE_SIZE;
          }

          if (bitmap) {
            ctx.drawImage(
              bitmap,
              0,
              0,
              bitmap.width,
              bitmap.height,
              0,
              0,
              TEXTURE_SIZE,
              TEXTURE_SIZE,
            );
          } else {
            ctx.fillStyle = '#ddd';
            ctx.fillRect(0, 0, TEXTURE_SIZE, TEXTURE_SIZE);
          }

          texture.needsUpdate = true;
          invalidate();
        }

        // loop
        runRAF();
      });
    };

    runRAF();

    return () => cancelAnimationFrame(rafID);
  }, [canvas, texture, invalidate]);

  return (
    <mesh ref={meshRef}>
      <meshBasicMaterial
        side={DoubleSide}
        transparent
        opacity={0.8}
        depthWrite={false}
        map={texture}
      />
    </mesh>
  );
}
