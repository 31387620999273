import cx from 'classnames';
import { shallow } from 'zustand/shallow';

import type { ButtonProps } from '@sb/design-system';
import { Button, HoldableButton, Icon } from '@sb/design-system';
import type { Space } from '@sb/routine-runner';
import getAdHocSpeedProfile from '@sbrc/components/visualizer-view-shared/getAdHocSpeedProfile';
import {
  useFeatureFlag,
  useGuidedMode,
  useIsAnotherSessionRunningAdHocCommand,
  useRoutineRunnerHandle,
} from '@sbrc/hooks';
import { areJointAnglesEqual } from '@sbrc/utils';

import { UNSAVED_POSITION } from '../../types';
import { useSpaceWidgetStore } from '../../useSpaceWidgetStore';

interface SyncButtonProps extends ButtonProps {
  position: Space.Position;
}

export function SyncButton({ position, ...rest }: SyncButtonProps) {
  const [robotID, isVizbot, targetJointAngles, setTargetJointAngles] =
    useSpaceWidgetStore(
      (s) =>
        [
          s.robotID,
          s.isVizbot,
          s.targetJointAngles,
          s.setTargetJointAngles,
        ] as const,
      shallow,
    );

  const isJointSync = areJointAnglesEqual(
    targetJointAngles,
    position.jointAngles,
  );

  const routineRunnerHandle = useRoutineRunnerHandle({ isVizbot });

  const isAdHocFullSpeed = useFeatureFlag('adHocFullSpeed');

  const isAnotherSessionRunningAdHocCommand =
    useIsAnotherSessionRunningAdHocCommand({ isVizbot });

  const { runAdHocCommand, stopGuidedMode } = useGuidedMode({ isVizbot });

  const props: ButtonProps = {
    size: 32,
    children: (
      <>
        <Icon kind={isJointSync ? 'joint' : 'tooltip'} />
        <span>Go here</span>
      </>
    ),
    ...rest,
    disabled: rest.disabled || isAnotherSessionRunningAdHocCommand,
    className: cx('tw-rounded-6', rest.className),
  };

  if (position === UNSAVED_POSITION) {
    return <Button {...props} disabled />;
  }

  return (
    <HoldableButton
      {...props}
      onHold={() => {
        const moveRobotToTargetPosition = async () => {
          const speedProfile = await getAdHocSpeedProfile(
            robotID,
            true,
            isAdHocFullSpeed,
          );

          if (isJointSync) {
            await routineRunnerHandle.moveToJointSpacePoint(
              position.jointAngles,
              speedProfile,
            );

            if (
              areJointAnglesEqual(
                position.jointAngles,
                routineRunnerHandle.getState()?.kinematicState.jointAngles,
              )
            ) {
              setTargetJointAngles(null);
            }

            return;
          }

          await routineRunnerHandle.moveToCartesianSpacePose(
            position.pose,
            'joint',
            speedProfile,
          );
        };

        runAdHocCommand({
          onRunCommand: moveRobotToTargetPosition,
        });
      }}
      onRelease={stopGuidedMode}
    />
  );
}
