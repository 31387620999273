import type { Space } from '@sb/routine-runner';

import { useVisualizePositionListInfo } from './position-list/useVisualizePositionListInfo';
import { Puck } from './Puck';

interface VisualizeSinglePositionProps {
  spaceItem: Space.SinglePosition;
}

export function VisualizeSinglePosition({
  spaceItem,
}: VisualizeSinglePositionProps) {
  const { isEditing, isVisualized } = useVisualizePositionListInfo(
    spaceItem.id,
  );

  if (!isVisualized) {
    return null;
  }

  const position = spaceItem.positions[0];

  return <Puck position={position?.pose} label="" isGray={!isEditing} />;
}
