import cx from 'classnames';
import { useState } from 'react';

import { Icon, Menu, MenuItem, Popper } from '@sb/design-system';
import type { Space } from '@sb/routine-runner';

import { useSpaceWidgetStore } from './useSpaceWidgetStore';

interface SpaceItemSettingsProps {
  spaceItem: Space.Item;
}

export function SpaceItemSettings({ spaceItem }: SpaceItemSettingsProps) {
  const setWidgetState = useSpaceWidgetStore((s) => s.setWidgetState);

  const [isOpen, setIsOpen] = useState(false);

  const dropdownContent = (
    <Menu>
      <MenuItem
        iconKind="pencil"
        onClick={(e) => {
          e.stopPropagation();
          setWidgetState({ renameItem: spaceItem });
        }}
      >
        Rename
      </MenuItem>
      <MenuItem
        iconKind="trash"
        className="tw-text-red"
        onClick={(e) => {
          e.stopPropagation();
          setWidgetState({ removeItemID: spaceItem.id });
        }}
      >
        Delete
      </MenuItem>
    </Menu>
  );

  return (
    <Popper
      content={dropdownContent}
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      placement="bottom-end"
      data-testid="space-widget-space-item-settings-button"
      onClick={(e) => {
        e.stopPropagation();
        setIsOpen(!isOpen);
      }}
      aria-label="Settings"
      className={cx(
        'tw-text-label-secondary',
        'tw-px-8',
        'tw-py-16',
        '-tw-my-16',
      )}
      offset={-12}
    >
      <Icon kind="ellipsisCircle" />
    </Popper>
  );
}
