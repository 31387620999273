import { v4 as uuid } from 'uuid';

import { Space } from '@sb/routine-runner';

import { UNSAVED_POSITION } from './types';

export function generateNewSpaceItem(
  kind: Space.ItemKind,
  name: string,
  description: string,
): Space.Item {
  const commonProps = {
    id: uuid(),
    name,
    description,
    kind,
  };

  switch (kind) {
    case 'gridPositionList':
      return Space.GridPositionList.parse(commonProps);
    case 'freeformPositionList': {
      const item = Space.FreeformPositionList.parse(commonProps);
      // default in an unsaved position to start off the list
      item.positions = [UNSAVED_POSITION];

      return item;
    }
    case 'singlePosition':
      return Space.SinglePosition.parse(commonProps);
    case 'visionPositionList':
      return Space.VisionPositionList.parse(commonProps);
    case 'plane':
      return Space.Plane.parse(commonProps);
    case 'environmentObject':
      return Space.EnvironmentObject.parse(commonProps);
    default: {
      const exhaustive: never = kind; // ensure type exhaustive
      throw new Error(`Space item kind ${exhaustive} not supported`);
    }
  }
}
