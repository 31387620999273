import cx from 'classnames';

import type { ButtonProps, IconKind } from '@sb/design-system';
import { Button, Icon, Tooltip } from '@sb/design-system';

interface WidgetIconButtonProps<WidgetKind extends string> extends ButtonProps {
  forWidget: WidgetKind;
  activeWidget: string | null;
  setActiveWidget: (kind: WidgetKind | null) => void;
  label: string;
  iconKind: IconKind;
  tooltip: string;
  isVizbot?: boolean;
}

export function WidgetIconButton<WidgetKind extends string>({
  forWidget,
  activeWidget,
  setActiveWidget,
  label,
  iconKind,
  tooltip,
  isVizbot,
  disabled,
  ...rest
}: WidgetIconButtonProps<WidgetKind>) {
  const isActive = !disabled && forWidget === activeWidget;

  return (
    <div
      className={cx(
        'tw-flex',
        'tw-flex-col',
        'tw-flex-nowrap',
        'tw-items-center',
        'tw-h-44',
        'tw-justify-end',
      )}
    >
      {!activeWidget && !disabled && (
        <div
          className={cx(
            'tw-rotate-[-90deg]',
            'tw-translate-y-[-10px]',
            'tw-text-13',
            'tw-whitespace-nowrap',
            'tw-w-0',
            'tw-pointer-events-none',
          )}
        >
          {label}
        </div>
      )}
      <Tooltip content={tooltip}>
        <Button
          variant={isActive ? 'Filled' : 'Elevated'}
          className={cx({ 'dark:tw-bg-fill-primary': !isActive })}
          size={44}
          onClick={() =>
            setActiveWidget(forWidget === activeWidget ? null : forWidget)
          }
          aria-label={label}
          disabled={disabled}
          {...rest}
        >
          <Icon
            kind={iconKind}
            className={cx(
              'tw-icon-28',
              !disabled && !isActive && 'tw-text-label-primary',
            )}
          />
        </Button>
      </Tooltip>
    </div>
  );
}
