import cx from 'classnames';

import { Icon } from '@sb/design-system';
import type { Robot } from '@sb/remote-control/types';
import {
  useIsRobotPaused,
  useRobotIOState,
  useRobotStateKind,
} from '@sbrc/hooks';

import IOSendSignalButton from '../../io-manager/IOSendSignalButton';

interface IOWidgetPortInfoProps {
  io: Robot.IOPort;
  isVizbot: boolean;
}

export function IOWidgetPortInfo({ io, isVizbot }: IOWidgetPortInfoProps) {
  const ioState = useRobotIOState({
    kind: io.kind,
    port: io.port,
    isVizbot,
  });

  const routineRunnerKind = useRobotStateKind({ isVizbot });
  const isPaused = useIsRobotPaused({ isVizbot });

  const isRoutinePlaying = routineRunnerKind === 'RoutineRunning' && !isPaused;

  const isSignalButtonDisabled = io.kind === 'Output' && isRoutinePlaying;

  // current robots do not have IO label on robot document
  // alternatively can update existing bots with migration
  const label = io.kind === 'Input' ? 'IN' : 'OUT';

  const ioCardHeader = (
    <div className="tw-flex tw-items-center tw-h-44 tw-mx-16 tw-border-b tw-border-divider-primary">
      <div className="tw-flex tw-items-center tw-px-8 tw-pt-2 tw-h-[22px] tw-min-w-[48px] tw-justify-center tw-rounded-6 tw-text-13 tw-font-bold tw-bg-fill-secondary">
        {io.label ?? label} {io.port}
      </div>

      {io.deviceName ? (
        <>
          <p className="tw-ml-8 tw-text-ellipsis">{io.deviceName}:</p>
          <p className="tw-ml-4 tw-text-ellipsis">{io.name}</p>
        </>
      ) : (
        <p className="tw-ml-8 tw-text-ellipsis">{io.name}</p>
      )}
    </div>
  );

  const canSendSignal = isVizbot
    ? io.kind === 'Input' || io.kind === 'Output'
    : io.kind === 'Output';

  return (
    <div className="tw-surface-elevated tw-rounded-10 tw-shadow-30">
      {ioCardHeader}

      <div className="tw-flex tw-h-44 tw-py-8 tw-grid-cols-2">
        <div className="tw-flex tw-items-center tw-w-1/2  tw-px-16 tw-border-r tw-border-divider-secondary">
          <div className="tw-flex tw-items-center tw-gap-4 tw-overflow-hidden">
            <Icon
              kind="circleHFill"
              className={cx(
                ioState === 'high' ? 'tw-text-green' : 'tw-text-metal-60',
              )}
            />

            <p
              className={cx(
                'tw-text-ellipsis',
                ioState === 'high'
                  ? 'tw-text-green'
                  : 'tw-text-label-secondary',
              )}
            >
              {io.highSignalName}
            </p>
          </div>

          {canSendSignal && (
            <IOSendSignalButton
              className="-tw-mr-4"
              kind={io.kind}
              ioPort={io.port}
              ioLevel="high"
              disabled={ioState === 'high' || isSignalButtonDisabled}
              isVizbot={isVizbot}
            />
          )}
        </div>

        <div className="tw-flex tw-items-center tw-w-1/2 tw-px-16">
          <div className="tw-flex tw-items-center tw-gap-4 tw-overflow-hidden">
            <Icon
              kind="circleLFill"
              className={cx(
                ioState === 'low' ? 'tw-text-green' : 'tw-text-metal-60',
              )}
            />

            <p
              className={cx(
                'tw-text-ellipsis',
                ioState === 'low' ? 'tw-text-green' : 'tw-text-label-secondary',
              )}
            >
              {io.lowSignalName}
            </p>
          </div>

          {canSendSignal && (
            <IOSendSignalButton
              className="-tw-mr-4"
              kind={io.kind}
              ioPort={io.port}
              ioLevel="low"
              disabled={ioState === 'low' || isSignalButtonDisabled}
              isVizbot={isVizbot}
            />
          )}
        </div>
      </div>
    </div>
  );
}
