import type { Space } from '@sb/routine-runner';

import { useSpaceWidgetStore } from '../../useSpaceWidgetStore';
import { useVisualizePositionListInfo } from '../position-list/useVisualizePositionListInfo';
import { VisualizePositions } from '../position-list/VisualizePositions';

import { GridBoundingBox } from './GridBoundingBox';
import { GridCorners } from './GridCorners';

interface VisualizeGridPositionListProps {
  spaceItem: Space.GridPositionList;
}

export function VisualizeGridPositionList({
  spaceItem,
}: VisualizeGridPositionListProps) {
  const { isVisualized, isEditing, currentPositionIndex } =
    useVisualizePositionListInfo(spaceItem.id);

  const currentView = useSpaceWidgetStore((s) => s.widgetState.currentView);

  if (!isVisualized) {
    return null;
  }

  const isSettingCorners =
    isEditing &&
    (currentView === 'gridSetup' || spaceItem.positions.length === 0);

  return (
    <>
      {isSettingCorners && <GridCorners spaceItem={spaceItem} />}

      <GridBoundingBox
        spaceItem={spaceItem}
        isSettingCorners={isSettingCorners}
      />

      {!isSettingCorners && (
        <VisualizePositions
          positions={spaceItem.positions}
          currentPositionIndex={currentPositionIndex}
        />
      )}
    </>
  );
}
