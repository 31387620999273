import cx from 'classnames';
import { shallow } from 'zustand/shallow';

import { Icon, NavigationBar, NavigationBarButton } from '@sb/design-system';
import type { Space } from '@sb/routine-runner';
import { useRoutineRunnerHandle } from '@sbrc/hooks';
import { getRobotPosition } from '@sbrc/utils';

import WidgetView from '../../widget-panel/WidgetView';
import { HeaderBackButton } from '../HeaderBackButton';
import type { WidgetState } from '../types';
import { UNSAVED_POSITION } from '../types';
import { useSpaceWidgetStore } from '../useSpaceWidgetStore';

import { PositionItem } from './position-item/PositionItem';

interface EditPositionListProps {
  spaceItem: Space.Item;
  children?: React.ReactNode;
}

export function EditPositionList({
  spaceItem,
  children,
}: EditPositionListProps) {
  const [isVizbot, widgetState, setWidgetState] = useSpaceWidgetStore(
    (s) => [s.isVizbot, s.widgetState, s.setWidgetState] as const,
    shallow,
  );

  const routineRunnerHandle = useRoutineRunnerHandle({ isVizbot });

  const positions = widgetState.item?.positions ?? [];

  const { currentPositionIndex = 0 } = widgetState;

  const handleSelectPosition = (index: number) => {
    setWidgetState({ ...widgetState, currentPositionIndex: index });
  };

  const handleAddPosition = (index: number, position: 'before' | 'after') => {
    const newPositions = [...positions];

    const offset = position === 'before' ? 0 : 1;
    const newIndex = index + offset;
    newPositions.splice(newIndex, 0, UNSAVED_POSITION);

    setWidgetState({
      ...widgetState,
      item: {
        ...spaceItem,
        positions: newPositions,
      },
      currentPositionIndex: newIndex,
    });
  };

  const updatePositions = (
    newPositions: Space.Position[],
    state?: Partial<WidgetState>,
  ) => {
    setWidgetState({
      ...widgetState,
      ...state,
      item: {
        ...spaceItem,
        positions: newPositions,
      },
      updateItem: {
        ...spaceItem,
        positions: newPositions.filter((p) => p !== UNSAVED_POSITION),
      },
    });
  };

  const handleSavePosition = (index: number) => {
    const robotPosition = getRobotPosition(routineRunnerHandle);

    if (robotPosition) {
      const newPositions = [...positions];
      newPositions[index] = robotPosition;
      updatePositions(newPositions);
    }
  };

  const isRemoveDisabled = positions.length <= 1;

  const handleDeletePosition = (index: number) => {
    if (isRemoveDisabled) {
      return;
    }

    const newPositions = [...positions];
    newPositions.splice(index, 1);

    updatePositions(newPositions, {
      currentPositionIndex: Math.min(newPositions.length - 1, index),
    });
  };

  const handleMovePosition = (index: number, newIndex: number) => {
    const newPositions = [...positions];
    const positionToMove = newPositions.splice(index, 1);
    newPositions.splice(newIndex, 0, ...positionToMove);
    updatePositions(newPositions, { currentPositionIndex: newIndex });
  };

  return (
    <WidgetView>
      <NavigationBar
        className="tw-pl-8 tw-pr-8"
        contentLeft={<HeaderBackButton />}
        contentRight={
          <NavigationBarButton
            onClick={() => handleAddPosition(positions.length, 'before')}
          >
            <Icon kind="plus" />
          </NavigationBarButton>
        }
      >
        {spaceItem.name}
      </NavigationBar>

      <div
        className={cx(
          'tw-flex-1',
          'tw-overflow-auto',
          'tw-flex',
          'tw-flex-col',
          'tw-gap-8',
          'tw-px-16',
        )}
      >
        {positions.map((position, index) => {
          const key = `${index}`;

          return (
            <PositionItem
              key={key}
              id={index}
              position={position}
              isSelected={currentPositionIndex === index}
              onSelect={() => handleSelectPosition(index)}
              onAddBefore={() => handleAddPosition(index, 'before')}
              onAddAfter={() => handleAddPosition(index, 'after')}
              onSave={() => handleSavePosition(index)}
              isRemoveDisabled={isRemoveDisabled}
              onRemove={() => handleDeletePosition(index)}
              isMoveUpDisabled={index === 0}
              onMoveUp={() => handleMovePosition(index, index - 1)}
              isMoveDownDisabled={index === positions.length - 1}
              onMoveDown={() => handleMovePosition(index, index + 1)}
            />
          );
        })}
      </div>

      <div className="tw-flex tw-flex-col tw-m-16 tw-gap-8">
        {children ?? (
          <p
            className={cx(
              'tw-flex-none',
              'tw-text-13',
              'tw-text-label-tertiary',
              'tw-text-ellipsis',
            )}
          >
            {positions.length}{' '}
            {positions.length === 1 ? 'position' : 'positions'}
          </p>
        )}
      </div>
    </WidgetView>
  );
}
