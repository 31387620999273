import { shallow } from 'zustand/shallow';

import { Menu, MenuItem, NavigationBarMenuButton } from '@sb/design-system';

import { UNSAVED_POSITION } from '../../types';
import { useSpaceWidgetStore } from '../../useSpaceWidgetStore';

export function EditPlaneMoreMenu() {
  const [setWidgetCurrentView, positionsCount] = useSpaceWidgetStore(
    (s) => [
      s.setWidgetCurrentView,
      s.widgetState.item?.positions.filter((p) => p !== UNSAVED_POSITION)
        .length ?? 0,
    ],
    shallow,
  );

  return (
    <NavigationBarMenuButton
      content={
        <Menu>
          <MenuItem
            onClick={() => setWidgetCurrentView('accuracyCalibration')}
            iconKind="circleHexagonPathCamera"
            disabled={positionsCount < 3}
          >
            Accuracy calibration
          </MenuItem>
          <MenuItem
            onClick={() => setWidgetCurrentView('info')}
            iconKind="infoCircle"
            disabled={positionsCount < 3}
          >
            Plane info
          </MenuItem>
        </Menu>
      }
    />
  );
}
