import pLimit from 'p-limit';

import { Grid, applyCompoundPose, invertPose, iterateGrid } from '@sb/geometry';
import type { ArmPosition, Space } from '@sb/routine-runner';
import { getMotionPlanner } from '@sbrc/services';

export async function generateGridPositions(
  gridPositionList: Space.GridPositionList,
  currentArmPostition: Space.Position | null,
  onPositionValidated: () => void,
  abortSignal: AbortSignal,
): Promise<Space.GridPositionList> {
  if (!currentArmPostition) {
    throw new Error('Unknown arm state');
  }

  const { cornerA, cornerB, cornerC, cornerD, numColumns, numRows } =
    gridPositionList;

  const seedPosition = cornerA ?? cornerB ?? cornerC ?? cornerD;

  if (!seedPosition) {
    throw new Error('Invalid grid specification');
  }

  const grid = Grid.safeParse({
    corners: {
      a: cornerA?.pose,
      b: cornerB?.pose,
      c: cornerC?.pose,
      d: cornerD?.pose,
    },
    rows: numRows,
    columns: numColumns,
  });

  if (!grid.success) {
    throw new Error('Invalid grid specification');
  }

  const motionPlanner = getMotionPlanner();

  const baseToWristTransform = await motionPlanner.forwardKinematics({
    checkValidity: false,
    gripperOpenness: 0.5,
    jointAngles: currentArmPostition.jointAngles,
  });

  // need to transform the raw position to calculate the FK for base to wrist
  const tcpToWristPlusBaseToOriginTransform = applyCompoundPose(
    baseToWristTransform.pose,
    invertPose(currentArmPostition.pose),
  );

  const checkAbortSignal = () => {
    if (abortSignal.aborted) {
      throw new Error('Generate grid positions aborted');
    }
  };

  // limit to 5 concurrent motion planner requests at once
  const limit = pLimit(5);

  const positionPromises: Array<Promise<ArmPosition>> = [
    ...iterateGrid(grid.data),
  ].map(({ position, row, column }) =>
    limit(async () => {
      checkAbortSignal();

      const pose = {
        x: position.x,
        y: position.y,
        z: position.z,
        i: seedPosition.pose.i,
        j: seedPosition.pose.j,
        k: seedPosition.pose.k,
        w: seedPosition.pose.w,
      };

      const solutions = await motionPlanner.inverseKinematics({
        checkValidity: true,
        pose: applyCompoundPose(tcpToWristPlusBaseToOriginTransform, pose),
        gripperOpenness: 0.5,
        jointSeed: seedPosition.jointAngles,
      });

      checkAbortSignal();

      if (
        solutions.every(
          (solution) => solution.isColliding || solution.violatesLimits,
        )
      ) {
        throw new Error(`Cannot reach row ${row + 1} column ${column + 1}`);
      }

      onPositionValidated();

      return {
        pose,
        jointAngles: solutions[0].jointAngles,
      };
    }),
  );

  return {
    ...gridPositionList,
    positions: await Promise.all(positionPromises),
  };
}
