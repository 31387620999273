import { NavigationBar } from '@sb/design-system';
import type { Robot } from '@sb/remote-control/types';

import WidgetView from '../widget-panel/WidgetView';

import { IOWidgetTabs } from './IOWidgetTabs';

interface IOWidgetProps {
  isVizbot: boolean;
  robot: Robot.ConvertedResponse;
}

export function IOWidget({ isVizbot, robot }: IOWidgetProps) {
  return (
    <WidgetView isFullHeight>
      <NavigationBar>I/O</NavigationBar>
      <div className="tw-flex tw-flex-col tw-flex-1 tw-overflow-auto">
        <IOWidgetTabs isVizbot={isVizbot} robot={robot} />
      </div>
    </WidgetView>
  );
}
