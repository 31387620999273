import cx from 'classnames';
import { isEqual } from 'lodash';

import {
  Button,
  Icon,
  Menu,
  MenuItem,
  SettingsGroupItem,
} from '@sb/design-system';
import { ZERO_POSE } from '@sb/geometry';
import type { Space } from '@sb/routine-runner';

import { NULL_JOINT_ANGLES } from '../../types';
import { PositionItemId } from '../position-item/PositionItemId';
import { PositionItemMoreMenu } from '../position-item/PositionItemMoreMenu';
import { SyncButton } from '../position-item/SyncButton';

interface AccuracyCalibrationEntryProps {
  id: number;
  calibrationEntry: Space.AccuracyCalibrationEntry;
  isSelected: boolean;
  onSelect: () => void;
  onAdd?: () => void;
  onSet?: () => void;
  onReset?: () => void;
  onDelete?: () => void;
}

export function AccuracyCalibrationEntry({
  id,
  calibrationEntry,
  isSelected,
  onSelect,
  onAdd,
  onSet,
  onReset,
  onDelete,
}: AccuracyCalibrationEntryProps) {
  const isCalibrated = !isEqual(calibrationEntry.offset, ZERO_POSE);

  return (
    <SettingsGroupItem
      isSeparated
      className={cx(isSelected && 'tw-outline-selected')}
      onMouseDown={onSelect}
      onTouchStart={onSelect}
      role="button"
    >
      <div className="tw-mr-auto">
        <PositionItemId id={onAdd ? '+' : id} isSelected={isSelected} />
      </div>

      {onSet && !isCalibrated && (
        <Button className="tw-rounded-6" size={32} onClick={onSet}>
          Set
        </Button>
      )}

      {onAdd && (
        <Button className="tw-rounded-6" size={32} onClick={onAdd}>
          <Icon kind="plus" />
          <span>Add</span>
        </Button>
      )}

      <SyncButton
        position={{
          jointAngles: NULL_JOINT_ANGLES,
          pose: onAdd
            ? calibrationEntry.rawPose
            : calibrationEntry.calibratedPose,
        }}
      />

      {onDelete && (
        <PositionItemMoreMenu
          content={
            <Menu>
              {isCalibrated && (
                <MenuItem onClick={onReset} iconKind="reset">
                  Reset
                </MenuItem>
              )}

              <MenuItem
                onClick={onDelete}
                iconKind="trash"
                className="tw-text-red"
              >
                Delete entry
              </MenuItem>
            </Menu>
          }
        />
      )}
    </SettingsGroupItem>
  );
}
