import { Icon } from '@sb/design-system';

interface CreateWithCameraMessageOverlayProps {
  isFound: boolean;
}

export function CreateWithCameraMessageOverlay({
  isFound,
}: CreateWithCameraMessageOverlayProps) {
  return (
    <div className="tw-absolute tw-inset-0 tw-flex tw-justify-center tw-items-center">
      <div className="tw-bg-light/[85%] tw-text-dark tw-rounded-18 tw-w-320 tw-py-32 tw-flex tw-flex-col tw-items-center tw-text-center">
        {isFound ? (
          <>
            <Icon className="tw-icon-42" kind="plane" />
            <h5 className="tw-text-20 tw-font-medium">
              Calibration board found.
            </h5>
            <p className="tw-text-15 tw-mt-20">
              Tap “Capture” to create the
              <br />
              plane from this surface.
            </p>
          </>
        ) : (
          <>
            <Icon className="tw-icon-42" kind="xmarkCircleFill" />
            <h5 className="tw-text-20 tw-font-medium">
              Can’t see calibration board.
            </h5>
            <p className="tw-text-15 tw-mt-20">
              Position the board so that all
              <br />
              corners are visible.
            </p>
          </>
        )}
      </div>
    </div>
  );
}
