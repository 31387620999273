import cx from 'classnames';

import { Button, Menu, MenuItem, SettingsGroupItem } from '@sb/design-system';
import type { Space } from '@sb/routine-runner';
import { areJointAnglesEqual } from '@sbrc/utils';

import { UNSAVED_POSITION } from '../../types';
import { useSpaceWidgetStore } from '../../useSpaceWidgetStore';

import { PositionItemId } from './PositionItemId';
import { PositionItemMoreMenu } from './PositionItemMoreMenu';
import { SyncButton } from './SyncButton';

export interface PositionItemProps {
  id: number;
  position: Space.Position;
  isSelected: boolean;
  onSelect: () => void;
  onAddBefore: () => void;
  onAddAfter: () => void;
  onSave: () => void;
  isRemoveDisabled: boolean;
  onRemove: () => void;
  isMoveUpDisabled: boolean;
  onMoveUp: () => void;
  isMoveDownDisabled: boolean;
  onMoveDown: () => void;
}

export function PositionItem({
  id,
  position,
  isSelected,
  onSelect,
  onAddBefore,
  onAddAfter,
  onSave,
  isRemoveDisabled,
  onRemove,
  isMoveUpDisabled,
  onMoveUp,
  isMoveDownDisabled,
  onMoveDown,
}: PositionItemProps) {
  const [targetJointAngles, setTargetJointAngles] = useSpaceWidgetStore(
    (s) => [s.targetJointAngles, s.setTargetJointAngles] as const,
  );

  const isUnsaved = position === UNSAVED_POSITION;

  return (
    <SettingsGroupItem
      isSeparated
      className={cx(isSelected && 'tw-outline-selected')}
      data-testid={`position-list-item-${id}`}
      onMouseDown={onSelect}
      onTouchStart={onSelect}
      role="button"
    >
      <PositionItemId id={id} isSelected={isSelected} />

      {isUnsaved ? (
        <Button
          variant="Filled"
          size={32}
          onClick={onSave}
          className="tw-rounded-6 tw-mx-auto"
        >
          Set
        </Button>
      ) : (
        <SyncButton className="tw-mx-auto" position={position} />
      )}

      <PositionItemMoreMenu
        content={
          <Menu>
            {!isUnsaved && (
              <MenuItem onClick={onSave} iconKind="plusCircle">
                Replace position
              </MenuItem>
            )}
            <MenuItem
              onClick={onRemove}
              iconKind="trash"
              className="tw-text-red"
              disabled={isRemoveDisabled}
            >
              Delete position
            </MenuItem>
            <MenuItem onClick={onAddBefore} iconKind="arrowUturnUp">
              Add position before
            </MenuItem>
            <MenuItem onClick={onAddAfter} iconKind="arrowUturnDown">
              Add position after
            </MenuItem>
            <MenuItem
              onClick={onMoveUp}
              iconKind="arrowUpLine"
              disabled={isMoveUpDisabled}
            >
              Reorder up
            </MenuItem>
            <MenuItem
              onClick={onMoveDown}
              iconKind="arrowDownLine"
              disabled={isMoveDownDisabled}
            >
              Reorder down
            </MenuItem>
            <MenuItem
              onClick={() => setTargetJointAngles(position.jointAngles)}
              disabled={areJointAnglesEqual(
                position.jointAngles,
                targetJointAngles,
              )}
              iconKind="joint"
            >
              Show saved joint angles
            </MenuItem>
          </Menu>
        }
      />
    </SettingsGroupItem>
  );
}
