import type { Space } from '@sb/routine-runner';

import { useVisualizePositionListInfo } from './useVisualizePositionListInfo';
import { VisualizePositions } from './VisualizePositions';

interface VisualizePositionListProps {
  spaceItem: Space.Item;
}

export function VisualizePositionList({
  spaceItem,
}: VisualizePositionListProps) {
  const { isVisualized, currentPositionIndex } = useVisualizePositionListInfo(
    spaceItem.id,
  );

  if (!isVisualized) {
    return null;
  }

  return (
    <VisualizePositions
      positions={spaceItem.positions}
      currentPositionIndex={currentPositionIndex}
    />
  );
}
