import { useFBX } from '@react-three/drei';

import type { InnerObjectProps } from './useInnerObject';
import { useInnerObject } from './useInnerObject';

export function InnerFBXObject({ environmentObject }: InnerObjectProps) {
  const object = useFBX(environmentObject.fileURL!);

  return useInnerObject(object, environmentObject);
}
