import { shallow } from 'zustand/shallow';

import type { Space } from '@sb/routine-runner';

import { isCalibrationEntryLocateResult } from '../../edit-space-item/plane/isCalibrationEntryLocateResult';
import { useSpaceWidgetStore } from '../../useSpaceWidgetStore';
import { Puck } from '../Puck';

import { VisualizePlaneSurface } from './VisualizePlaneSurface';

interface VisualizePlaneProps {
  spaceItem: Space.Plane;
}

export function VisualizePlane({ spaceItem }: VisualizePlaneProps) {
  const [isEditing, currentPositionIndex, isAccuracyCalibrationView] =
    useSpaceWidgetStore(
      (s) => [
        s.widgetState.item?.id === spaceItem.id,
        s.widgetState.currentPositionIndex,
        s.widgetState.currentView === 'accuracyCalibration' ||
          s.widgetState.currentView === 'cameraSettings' ||
          s.widgetState.currentView === 'locateSettings',
      ],
      shallow,
    );

  const currentEntry =
    currentPositionIndex === undefined
      ? undefined
      : spaceItem.calibrationEntries[currentPositionIndex];

  if (isEditing) {
    return (
      <>
        <VisualizePlaneSurface
          spaceItem={spaceItem}
          showAxes={!isAccuracyCalibrationView}
        />
        {isAccuracyCalibrationView && currentEntry && (
          <Puck position={currentEntry.rawPose} label="R" isGray />
        )}
        {isAccuracyCalibrationView &&
          currentEntry &&
          !isCalibrationEntryLocateResult(currentEntry) && (
            <Puck position={currentEntry.calibratedPose} label="C" />
          )}
      </>
    );
  }

  return null;
}
