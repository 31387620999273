interface AppFooterProps {
  left?: React.ReactNode;
  middle?: React.ReactNode;
  right?: React.ReactNode;
}

export function AppFooter({ left, middle, right }: AppFooterProps) {
  return (
    <footer className="tw-fixed tw-z-20 tw-bottom-16 tw-left-0 tw-right-0 tw-h-44 tw-flex tw-gap-16 tw-items-center">
      <div className="tw-flex tw-flex-1 tw-justify-end tw-pl-16 tw-gap-16 tw-w-0 ">
        {left}
        <div className="tw-flex-1" />
      </div>
      <div className="tw-flex tw-gap-16 tw-flex-none">{middle}</div>
      <div className="tw-flex tw-flex-1 tw-pr-16 tw-gap-16 tw-w-0 ">
        <div className="tw-flex-1" />
        {right}
      </div>
    </footer>
  );
}
