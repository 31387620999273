import { NavigationBarBackButton } from '@sb/design-system';

import { useSpaceWidgetStore } from './useSpaceWidgetStore';

interface HeaderBackButtonProps {
  onBack?: () => void;
}

export function HeaderBackButton({ onBack }: HeaderBackButtonProps) {
  const [setWidgetState, setTargetJointAngles] = useSpaceWidgetStore(
    (s) => [s.setWidgetState, s.setTargetJointAngles] as const,
  );

  return (
    <NavigationBarBackButton
      data-testid="space-widget-header-back-button"
      onClick={() => {
        if (onBack) {
          onBack();
        } else {
          setWidgetState({});
        }

        setTargetJointAngles(null);
      }}
    />
  );
}
