import { isEqual } from 'lodash';
import { useState } from 'react';
import { shallow } from 'zustand/shallow';

import {
  Button,
  Icon,
  NavigationBar,
  SettingsGroup,
  SettingsGroupNavigationItem,
} from '@sb/design-system';
import { ZERO_POSE, ZERO_ROTATION } from '@sb/geometry';
import * as log from '@sb/log';
import type { RunLocateResult, Space } from '@sb/routine-runner';
import { Blobs2DOverlay, CameraStream } from '@sbrc/components/camera';
import { useRoutineRunnerHandle } from '@sbrc/hooks';
import { getRobotPosition } from '@sbrc/utils';

import WidgetView from '../../../widget-panel/WidgetView';
import { HeaderBackButton } from '../../HeaderBackButton';
import { UNSAVED_POSITION } from '../../types';
import { useSpaceWidgetStore } from '../../useSpaceWidgetStore';
import { LabeledPositionItem } from '../position-item/LabeledPositionItem';

import { AccuracyCalibrationEntry } from './AccuracyCalibrationEntry';
import { getUpdateItem } from './getUpdateItem';
import { isCalibrationEntryLocateResult } from './isCalibrationEntryLocateResult';
import { useAccuracyCalibrationLocateResults } from './useAccuracyCalibrationLocateResults';

const ns = log.namespace('plane.accuracyCalibration');

interface AccuracyCalibrationProps {
  spaceItem: Space.Plane;
}

export function AccuracyCalibration({ spaceItem }: AccuracyCalibrationProps) {
  const [
    isVizbot,
    currentPositionIndex,
    setWidgetItem,
    setWidgetCurrentView,
    setCurrentPositionIndex,
  ] = useSpaceWidgetStore(
    (s) => [
      s.isVizbot,
      s.widgetState.currentPositionIndex,
      s.setWidgetItem,
      s.setWidgetCurrentView,
      s.setCurrentPositionIndex,
    ],
    shallow,
  );

  const { locateStatus, getLocateResults, setCurrentLocateResult } =
    useAccuracyCalibrationLocateResults(spaceItem);

  const [locateResults, setLocateResults] = useState<
    RunLocateResult['results']
  >([]);

  const routineRunnerHandle = useRoutineRunnerHandle({ isVizbot });

  const calibrationEntries = spaceItem.calibrationEntries.filter(
    (entry) => !isCalibrationEntryLocateResult(entry),
  );

  return (
    <WidgetView>
      <NavigationBar
        className="tw-pl-8 tw-pr-8"
        contentLeft={
          <HeaderBackButton onBack={() => setWidgetCurrentView(undefined)} />
        }
        onClick={() =>
          log.info(
            ns`plane.accuracyCalibration`,
            'Calibration Data',
            JSON.stringify(spaceItem.calibrationEntries),
          )
        }
      >
        Accuracy calibration
      </NavigationBar>

      <div className="tw-mx-16 tw-mb-16">
        <CameraStream
          className="tw-rounded-10 tw-surface-elevated tw-shadow-30 tw-overflow-hidden"
          isPaused={locateResults.length > 0}
        >
          <Blobs2DOverlay
            blobs={locateResults.map(({ blob }) => blob)}
            size="large"
          />
        </CameraStream>
      </div>

      <div className="tw-px-16 tw-flex-1 tw-overflow-auto tw-flex tw-flex-col tw-gap-16">
        <p className="tw-text-15">
          Set a home position where the wrist camera has full view of the plane
        </p>

        <SettingsGroup>
          <LabeledPositionItem
            label="Home"
            position={spaceItem.calibrationHomePosition ?? UNSAVED_POSITION}
            onSave={() => {
              const robotPosition = getRobotPosition(routineRunnerHandle);

              if (robotPosition) {
                setWidgetItem(
                  { ...spaceItem, calibrationHomePosition: robotPosition },
                  getUpdateItem,
                );
              }
            }}
          />
        </SettingsGroup>

        <SettingsGroup>
          <SettingsGroupNavigationItem
            label="Camera settings"
            onClick={() => setWidgetCurrentView('cameraSettings')}
          />
        </SettingsGroup>

        {locateResults.length > 0 && (
          <section className="tw-flex-col">
            <h5 className="tw-flex-none tw-pl-16 tw-heading-40">
              <span>Locate results</span>
              <span className="tw-font-regular tw-text-15 tw-text-label-tertiary">
                ({locateResults.length})
              </span>
            </h5>

            <div className="tw-flex tw-flex-col tw-gap-8">
              {locateResults.map((result, index) => {
                const calibrationEntry = {
                  rawPose: result.pose,
                  calibratedPose: ZERO_POSE,
                  offset: ZERO_POSE,
                };

                return (
                  <AccuracyCalibrationEntry
                    key={index} // eslint-disable-line react/no-array-index-key
                    id={-1}
                    calibrationEntry={calibrationEntry}
                    isSelected={isEqual(
                      calibrationEntry,
                      spaceItem.calibrationEntries[currentPositionIndex ?? -1],
                    )}
                    onSelect={() => setCurrentLocateResult(result)}
                    onAdd={() => {
                      const newCalibrationEntries = [...calibrationEntries];

                      newCalibrationEntries.push({
                        rawPose: result.pose,
                        calibratedPose: result.pose,
                        offset: ZERO_POSE,
                      });

                      setWidgetItem(
                        {
                          ...spaceItem,
                          calibrationEntries: newCalibrationEntries,
                        },
                        getUpdateItem,
                      );

                      setCurrentPositionIndex(newCalibrationEntries.length - 1);
                    }}
                  />
                );
              })}
            </div>
          </section>
        )}

        <section className="tw-flex-col">
          <h5 className="tw-flex-none tw-pl-16 tw-heading-40">
            <span>Calibration entries</span>
            <span className="tw-font-regular tw-text-15 tw-text-label-tertiary">
              ({calibrationEntries.length})
            </span>
          </h5>

          <div className="tw-flex tw-flex-col tw-gap-8">
            {calibrationEntries.map((entry, index) => (
              <AccuracyCalibrationEntry
                key={index} // eslint-disable-line react/no-array-index-key
                id={index}
                calibrationEntry={entry}
                isSelected={index === currentPositionIndex}
                onSelect={() => setCurrentPositionIndex(index)}
                onSet={() => {
                  const robotPosition = getRobotPosition(routineRunnerHandle);

                  if (robotPosition) {
                    const newCalibrationEntries = [
                      ...spaceItem.calibrationEntries,
                    ];

                    newCalibrationEntries[index] = {
                      ...entry,
                      calibratedPose: robotPosition.pose,
                      offset: {
                        x: robotPosition.pose.x - entry.rawPose.x,
                        y: robotPosition.pose.y - entry.rawPose.y,
                        z: robotPosition.pose.z - entry.rawPose.z,
                        ...ZERO_ROTATION,
                      },
                    };

                    setWidgetItem(
                      {
                        ...spaceItem,
                        calibrationEntries: newCalibrationEntries,
                      },
                      getUpdateItem,
                    );
                  }
                }}
                onReset={() => {
                  const newCalibrationEntries = [
                    ...spaceItem.calibrationEntries,
                  ];

                  newCalibrationEntries[index] = {
                    ...entry,
                    calibratedPose: entry.rawPose,
                    offset: ZERO_POSE,
                  };

                  setWidgetItem(
                    { ...spaceItem, calibrationEntries: newCalibrationEntries },
                    getUpdateItem,
                  );
                }}
                onDelete={() => {
                  const newCalibrationEntries = [
                    ...spaceItem.calibrationEntries,
                  ];

                  newCalibrationEntries.splice(index, 1);

                  setWidgetItem(
                    { ...spaceItem, calibrationEntries: newCalibrationEntries },
                    getUpdateItem,
                  );
                }}
              />
            ))}
          </div>
        </section>
      </div>

      <Button
        variant="Filled"
        className="tw-rounded-6 tw-m-16"
        disabled={locateStatus === 'loading'}
        onClick={async () => {
          const results = await getLocateResults();
          setLocateResults(results);
          setCurrentLocateResult(results[0]);
        }}
      >
        <Icon kind="arrowCirclePath" />
        <span>
          {locateStatus === 'loading' ? 'Locating' : 'Locate to add new entry'}
        </span>
      </Button>
    </WidgetView>
  );
}
