import cx from 'classnames';
import { map, range } from 'itertools';
import { clamp } from 'lodash';
import { Fragment } from 'react';

import type { GridCorner } from './corners';

const SIZE = 100;
const PADDING = 50;
const SQUASH = 0.5;
const TEXT_POSITION = 90;

const START_POSITION = PADDING - SIZE;
const END_POSITION = SIZE - PADDING;

const LABELS = [
  {
    label: 'A',
    x: 0,
    y: -TEXT_POSITION * SQUASH,
  },
  {
    label: 'B',
    x: -TEXT_POSITION,
    y: 0,
  },
  {
    label: 'C',
    x: 0,
    y: TEXT_POSITION * SQUASH,
  },
  {
    label: 'D',
    x: TEXT_POSITION,
    y: 0,
  },
];

const MIN_ITEM_SIZE = 5;
const MAX_ITEM_SIZE = 30;
const MIN_ITEM_PADDING = 7;
const ITEM_OUTER_PADDING = 2;
const ITEM_START_POSITION = PADDING + ITEM_OUTER_PADDING - SIZE;
const ITEM_END_POSITION = SIZE - PADDING - ITEM_OUTER_PADDING;

const MIN_GENERATE_ITEMS = 0;
const MAX_GENERATE_ITEMS = 6;

interface GridItem {
  x: number;
  y: number;
  size: number;
  corner: GridCorner | '';
}

function* generateGridItems(
  unclampedColumns?: number,
  unclampedRows?: number,
): Generator<GridItem> {
  if (unclampedColumns && unclampedRows) {
    const numColumns = clamp(
      unclampedColumns,
      MIN_GENERATE_ITEMS,
      MAX_GENERATE_ITEMS,
    );

    const numRows = clamp(
      unclampedRows,
      MIN_GENERATE_ITEMS,
      MAX_GENERATE_ITEMS,
    );

    const itemCount = Math.max(numColumns, numRows);
    const itemAreaSize = ITEM_END_POSITION - ITEM_START_POSITION;

    const defaultSize =
      (itemAreaSize - MIN_ITEM_PADDING * (itemCount - 1)) / itemCount;

    const size = clamp(defaultSize, MIN_ITEM_SIZE, MAX_ITEM_SIZE);

    const colMargin =
      numColumns > 1
        ? (itemAreaSize - numColumns * size) / (numColumns - 1)
        : 0;

    const rowMargin =
      numRows > 1 ? (itemAreaSize - numRows * size) / (numRows - 1) : 0;

    const colRange = range(numColumns);

    const getCorner = (colIndex: number, rowIndex: number) => {
      if (colIndex === 0) {
        if (rowIndex === 0) {
          return 'cornerA';
        }

        if (rowIndex === numRows - 1) {
          return 'cornerB';
        }
      } else if (colIndex === numColumns - 1) {
        if (rowIndex === 0) {
          return 'cornerD';
        }

        if (rowIndex === numRows - 1) {
          return 'cornerC';
        }
      }

      return '';
    };

    for (const colIndex of colRange) {
      const rowRange = range(numRows);

      for (const rowIndex of rowRange) {
        yield {
          x: ITEM_START_POSITION + rowIndex * (size + rowMargin),
          y: ITEM_START_POSITION + colIndex * (size + colMargin),
          size,
          corner: getCorner(colIndex, rowIndex),
        };
      }
    }
  }
}

export interface GridDiagramProps {
  numColumns?: number;
  numRows?: number;
  className?: string;
  unsavedCorners: string[];
}

export function GridDiagram({
  numColumns,
  numRows,
  className,
  unsavedCorners,
}: GridDiagramProps) {
  return (
    <svg
      viewBox={`${-SIZE} ${-SIZE * SQUASH} ${SIZE * 2} ${SIZE * SQUASH * 2}`}
      className={className}
    >
      <g transform={`scale(-1, ${SQUASH}) rotate(45)`}>
        <rect
          x={START_POSITION}
          y={START_POSITION}
          width={END_POSITION - START_POSITION}
          height={END_POSITION - START_POSITION}
          className="tw-fill-fill-primary"
        />
        {map(
          generateGridItems(numColumns, numRows),
          ({ x, y, size, corner }) => {
            const isCorner = corner !== '';

            const isSaved = !unsavedCorners.includes(corner);
            const isInferred = !isSaved && unsavedCorners.length === 1;
            const isUnsaved = !isSaved && !isInferred;

            return (
              <Fragment key={`${x}/${y}`}>
                <rect
                  x={x}
                  y={y}
                  width={size}
                  height={size}
                  className="tw-fill-fill-primary"
                />
                {isCorner && (
                  <circle
                    cx={x + size / 2}
                    cy={y + size / 2}
                    r={size / 4}
                    className={cx(
                      isSaved && 'tw-fill-green',
                      isUnsaved && 'tw-fill-mango',
                      isInferred && 'tw-fill-fill-secondary',
                    )}
                  />
                )}
              </Fragment>
            );
          },
        )}
      </g>
      {LABELS.map(({ label, x, y }) => (
        <text
          key={label}
          x={x}
          y={y}
          className={cx('tw-fill-blue', 'tw-text-13')}
          alignmentBaseline="central"
          textAnchor="middle"
        >
          {label}
        </text>
      ))}
    </svg>
  );
}
