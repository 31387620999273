import { shallow } from 'zustand/shallow';

import { useFeatureFlag } from '@sbrc/hooks';

import { useSpaceWidgetStore } from '../../useSpaceWidgetStore';

export function useVisualizePositionListInfo(id: string) {
  const isVisualizedWhenNotEditing = useFeatureFlag(
    'visualizePositionListsWhenNotEditing',
  );

  const [isEditing, isNotEditingAnything, currentPositionIndex] =
    useSpaceWidgetStore(
      (s) =>
        [
          s.widgetState.item?.id === id,
          !s.widgetState.item,
          s.widgetState.currentPositionIndex,
        ] as const,
      shallow,
    );

  return {
    isVisualized:
      isEditing || (isNotEditingAnything && isVisualizedWhenNotEditing),
    isEditing,
    currentPositionIndex: isEditing ? currentPositionIndex ?? 0 : undefined,
  };
}
