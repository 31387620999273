import { useMemo } from 'react';
import { shallow } from 'zustand/shallow';

import { useSpaceWidgetStore } from '../useSpaceWidgetStore';

import { VisualizeEnvironmentObject } from './environment-objects/VisualizeEnvironmentObject';
import { VisualizeGridPositionList } from './grid-position-list/VisualizeGridPositionList';
import { VisualizePlane } from './plane/VisualizePlane';
import { VisualizePositionList } from './position-list/VisualizePositionList';
import { VisualizeSinglePosition } from './VisualizeSinglePosition';
import { VisualizeVisionPositionList } from './VisualizeVisionPositionList';

export function VisualizeSpaceItems() {
  const [editingSpaceItem, space] = useSpaceWidgetStore(
    (s) => [s.widgetState.item, s.routine?.space],
    shallow,
  );

  const spaceItems = useMemo(() => {
    let items = space ?? [];

    if (editingSpaceItem) {
      items = items.filter((i) => i.id !== editingSpaceItem.id);
      items.push(editingSpaceItem);
    }

    return items;
  }, [editingSpaceItem, space]);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {spaceItems.map((spaceItem) => {
        const { kind, id } = spaceItem;

        switch (kind) {
          case 'freeformPositionList':
            return <VisualizePositionList key={id} spaceItem={spaceItem} />;
          case 'gridPositionList':
            return <VisualizeGridPositionList key={id} spaceItem={spaceItem} />;
          case 'singlePosition':
            return <VisualizeSinglePosition key={id} spaceItem={spaceItem} />;
          case 'visionPositionList':
            return (
              <VisualizeVisionPositionList key={id} spaceItem={spaceItem} />
            );
          case 'plane':
            return <VisualizePlane key={id} spaceItem={spaceItem} />;
          case 'environmentObject':
            return (
              <VisualizeEnvironmentObject key={id} spaceItem={spaceItem} />
            );
          default: {
            const exhaustive: never = kind; // ensure type exhaustive
            throw new Error(`Unexpected kind for equipment ${exhaustive}`);
          }
        }
      })}
    </>
  );
}
