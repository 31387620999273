/* eslint-disable no-param-reassign */

import { useThree } from '@react-three/fiber';
import { useLayoutEffect, useMemo, useRef } from 'react';
import type { Object3D } from 'three';
import { DoubleSide, Mesh } from 'three';
import { SkeletonUtils } from 'three-stdlib';

import type { Space } from '@sb/routine-runner';

export interface InnerObjectProps {
  environmentObject: Space.EnvironmentObject;
}

export function useInnerObject(
  object: Object3D,
  { pose, scale = 1 }: Space.EnvironmentObject,
) {
  const objectClone = useMemo(() => {
    // clone the scene so it can appear multiple times if needed
    return SkeletonUtils.clone(object);
  }, [object]);

  const invalidate = useThree((state) => state.invalidate);

  const primitiveRef = useRef<Object3D>(null!);

  useLayoutEffect(() => {
    primitiveRef.current.quaternion.set(pose.i, pose.j, pose.k, pose.w);
    primitiveRef.current.position.set(pose.x, pose.y, pose.z);
    primitiveRef.current.scale.set(scale, scale, scale);
    invalidate();
  }, [pose, scale, invalidate]);

  useLayoutEffect(() => {
    objectClone.traverse((child) => {
      if (child instanceof Mesh) {
        child.material.side = DoubleSide;
        child.material.transparent = true;
        child.material.opacity = 0.4;
      }
    });
  }, [objectClone]);

  return <primitive ref={primitiveRef} object={objectClone} />;
}
