import { getEquipmentByKind } from '@sb/integrations/frontend/getEquipmentByKind';
import { useEquipment } from '@sbrc/hooks';

import { WidgetIconButton } from './WidgetIconButton';

interface EquipmentWidgetIconButtonsProps {
  activeWidget: string | null;
  setActiveWidget: (kind: `equipment-${string}` | null) => void;
  isVizbot?: boolean;
  disabled?: boolean;
}

export function EquipmentWidgetIconButtons({
  activeWidget,
  setActiveWidget,
  isVizbot,
  disabled,
}: EquipmentWidgetIconButtonsProps) {
  const equipment = useEquipment();

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {equipment?.map(({ id, config }) => {
        const integration = getEquipmentByKind(config.kind);

        if (!integration.Widget) {
          return null;
        }

        const forWidget = `equipment-${config.kind}` as const;

        const getDataButtonID = () => {
          // this is a work around so the control can be opened from
          // elsewhere in the app without needing to move state up the tree
          if (integration.getIntegrationType() === 'EndEffector') {
            if (activeWidget === forWidget) {
              return 'close-gripper-widget';
            }

            return 'open-gripper-widget';
          }

          return undefined;
        };

        return (
          <WidgetIconButton
            key={id}
            activeWidget={activeWidget}
            setActiveWidget={setActiveWidget}
            isVizbot={isVizbot}
            disabled={disabled}
            forWidget={forWidget}
            label={integration.getShortName()}
            iconKind={integration.iconKind}
            tooltip={`Control the ${integration.getDisplayName()}`}
            data-testid={`${config.kind}-widget-button`}
            data-widget-button-id={getDataButtonID()}
          />
        );
      })}
    </>
  );
}
